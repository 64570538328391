import React, { useEffect, useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import { CVInterface } from '../../../interfaces/CVInterface';
import { Staff } from '../../../interfaces/Staff';
import { WorkRecord } from '../../../interfaces/WorkRecord';
import { ElementWithPermission } from '../../ElementWithPermission/ElementWithPermission';
import { ROLES, COMMON } from '../../../constants';
import EducationClient from '../../../services/http/EducationClient';
import DictionaryStore from '../../../services/dictionary/DictionaryStore';

type PropsType = {
  data: CVInterface
  staff: Staff
  workRecord: WorkRecord
}

export const WRCvData: React.FC<PropsType> = (props: PropsType) => {
  const { data, staff, workRecord } = { ...props };
  const [educationItems, setEducationItems] = useState<Array<any> | null>(null);

  useEffect(() => {
    if (staff.id) {
      DictionaryStore.getDegrees()
        .then((degrees: any) => {
          EducationClient.getEducationItems(staff.id).then((educationItems: any) => {
            if (educationItems.length && degrees.length) {
              educationItems.forEach((item: any) => {
                item.degree = degrees[item.degree];
              });
              setEducationItems(educationItems);
            }
          });
        });
    }
  }, [staff]);

  const arrayWithoutBlankLines = (array: any) => {
    return array.filter((value: any) => {
      return Boolean(value);
    });
  }

  const isObjectEmpty = (obj: any) => {
    let isEmpty = true;
    if (typeof obj === 'string') {
      if (obj) {
        isEmpty = false;
      }
    } else if (typeof obj === 'object') {
      for (let element in obj) {
        if (element !== '$$hashKey') {
          if (!isObjectEmpty(obj[element])) {
            isEmpty = false;
          }
        }
      }
    } else if (Array.isArray(obj)) {
      if (arrayWithoutBlankLines(obj).length > 0) {
        isEmpty = false;
      }
    }

    return isEmpty;
  }

  const isSuperArrayEmpty = (array: any) => {
    let isEmpty = true;
    if (typeof array !== 'undefined') {
      array.forEach((element: any) => {
        if (!isObjectEmpty(element)) {
          isEmpty = false;
        }
      });
    }
    return isEmpty;
  }

  if (!data) return <></>

  return (
    <>
      {
        (data.data?.summary)
          ? (
            <section className="cv-section">
              <div>
                <p className="cv-section__title">Summary Background</p>
                <pre>{ data.data.summary }</pre>
              </div>
            </section>
          )
          : <></>
      }
      {
        (data.data?.coreskills && data.data?.coreskills.length > 0)
          ? (
            <section className="cv-section">
              <div>
                <p className="cv-section__title">Core Skills</p>
                <ul>
                  {
                    data.data.coreskills.map((item: string, key: number) => {
                      return <li key={ key }>{item}</li>
                    })
                  }
                </ul>
              </div>
            </section>
          )
          : <></>
      }
      {
        (data.data?.work2 && data.data.work2.length > 0)
          ? (
            <section className="cv-section">
              <div>
                <p className="cv-section__title">Work Experience In { COMMON.GENERAL.APP_NAME }</p>
                <div>
                  {
                    (data.data.work2 && !isSuperArrayEmpty(data.data.work2))
                      ? (
                        data.data.work2.map((work: any, key: number) => {
                          return (!isObjectEmpty(work))
                            ? (
                              <div key={ key }>
                                <div className="d-flex flex-row">
                                  <div className="col">
                                    <p className="text-muted m-0">Position</p>
                                    <p style={{ fontSize: '14px', margin: '5px 0 20px' }}>{ work.position3 }</p>
                                  </div>
                                  <div className="col">
                                    <p className="float-right" style={{ fontSize: '14px', margin: '5px 0 20px' }}>
                                      <span>
                                        { work.officelocation }
                                        { (work.from && work.officelocation) ? ' - ' : '' }
                                        { (work.from) ? new Date(work.from).getUTCFullYear() : '' }
                                        { work.from && (work.tillpresent || work.to) ? ' - ' : '' }
                                        { (work.tillpresent) ? 'Present' : (work.to ? new Date(work.to).getUTCFullYear() : '') }
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="col">
                                    <p className="text-muted m-0">Project</p>
                                    <p style={{ fontSize: '14px', margin: '5px 0 20px' }}>{ work.projectname }</p>
                                  </div>
                                  <div className="col">
                                    <p className="text-muted m-0">Project description</p>
                                    <p style={{ fontSize: '14px', margin: '5px 0 20px' }}>{ work.projectdescription }</p>
                                  </div>
                                  <div className="col">
                                    <p className="text-muted m-0">Technologies used</p>
                                    <p style={{ fontSize: '14px', margin: '5px 0 20px' }}>{ work.technologiesused2 }</p>
                                  </div>
                                  {
                                    (work.responsibilities2?.length > 0)
                                      ? (
                                        <div className="col">
                                          <p className="text-muted m-0">Responsibilities</p>
                                          <ul style={{ margin: '5px 0 20px' }}>
                                            {
                                              work.responsibilities2.map((responsibility: string, i: number) => {
                                                return responsibility ? <li key={ i }>{responsibility}</li> : '';
                                              })
                                            }
                                          </ul>
                                        </div>
                                      )
                                      : <></>
                                  }
                                </div>
                              </div>
                            )
                            : <></>
                        })
                      )
                      : <></>
                  }
                </div>
              </div>
            </section>
          )
          : <></>
      }
      {
        (data.data?.workexperiencebeforeCogniance2 && data.data.workexperiencebeforeCogniance2.length > 0)
          ? (
            <section className="cv-section">
              <div>
                <p className="cv-section__title">Work Experience Before Star</p>
                <div>
                  {
                    (data.data.workexperiencebeforeCogniance2 && !isSuperArrayEmpty(data.data.workexperiencebeforeCogniance2))
                      ? (
                        data.data.workexperiencebeforeCogniance2.map((work: any, key: number) => {
                          return (!isObjectEmpty(work))
                            ? (
                              <div key={ key }>
                                <div className="d-flex flex-row">
                                  {
                                    (work.company2)
                                      ? (
                                        <div className="col">
                                          <p className="text-muted m-0">Company</p>
                                          <p style={{ fontSize: '14px' }}>{ work.company2 }</p>
                                        </div>
                                      )
                                      : <></>
                                  }
                                  <div className="col">
                                    <p className="float-right" style={{ fontSize: '14px', margin: '5px 0 20px' }}>
                                      <span>
                                        { work.officelocation2 }
                                        { (work.from2 && work.officelocation2) ? ' - ' : '' }
                                        { (work.from2) ? new Date(work.from2).getUTCFullYear() : '' }
                                        { work.from2 && (work.tillpresent || work.to2) ? ' - ' : '' }
                                        { (work.tillpresent) ? 'Present' : (work.to2 ? new Date(work.to2).getUTCFullYear() : '') }
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column">
                                  {
                                    (work.position2)
                                      ? (
                                        <div className="col">
                                          <p className="text-muted m-0">Position</p>
                                          <p style={{ fontSize: '14px', margin: '5px 0 20px' }}>{ work.position2 }</p>
                                        </div>
                                      )
                                      : <></>
                                  }
                                  {
                                    (work.projectdescription)
                                      ? (
                                        <div className="col">
                                          <p className="text-muted m-0">Project description</p>
                                          <p style={{ fontSize: '14px', margin: '5px 0 20px' }}>{ work.projectdescription }</p>
                                        </div>
                                      )
                                      : <></>
                                  }
                                  {
                                    (work.technologiesused)
                                      ? (
                                        <div className="col">
                                          <p className="text-muted m-0">Technologies used</p>
                                          <p style={{ fontSize: '14px', margin: '5px 0 20px' }}>{ work.technologiesused }</p>
                                        </div>
                                      )
                                      : <></>
                                  }
                                  {
                                    (work.responsibilities?.length > 0)
                                      ? (
                                        <div className="col">
                                          <p className="text-muted m-0">Responsibilities</p>
                                          <ul style={{ margin: '5px 0 20px' }}>
                                            {
                                              work.responsibilities.map((responsibility: string, i: number) => {
                                                return responsibility ? <li key={ i }>{responsibility}</li> : '';
                                              })
                                            }
                                          </ul>
                                        </div>
                                      )
                                      : <></>
                                  }
                                </div>
                              </div>
                            )
                            : <></>
                        })
                      )
                      : <></>
                  }
                </div>
              </div>
            </section>
          )
          : <></>
      }
      {
        (educationItems?.length && !isSuperArrayEmpty(educationItems))
          ? (
            <section className="cv-section">
              <div>
                <p className="cv-section__title">
                  Education
                  <ElementWithPermission element="editEducationLink" staffToCheck={{role: ROLES.LM, wrId: workRecord.id}}>
                    <NavLink className="ml-2 text-muted" to={`/staff/${staff.id}/work-records/${workRecord.id}/education`}>
                      <FA icon={['fas', 'pen']} style={{fontSize: '11px', verticalAlign: '-0.1em'}}/>
                    </NavLink>
                  </ElementWithPermission>
                </p>
                <div>
                  {
                    educationItems.map((education: any, key: number) => {
                      return (!isObjectEmpty(education))
                        ? (
                          <div key={ key } style={{ marginBottom: '34px' }}>
                            <div className="d-flex flex-row">
                              <div className="col">
                                <p style={{ fontSize: '14px', marginBottom: '5px' }}>
                                  {
                                    (education.degree)
                                      ? (
                                        <>
                                          <span className="font-weight-bold">{ education.degree }</span>
                                          <span> Degree</span>
                                        </>
                                      )
                                      : <></>
                                  }
                                  { (education.field_of_study && education.degree) ? <span> in </span> : <></> }
                                  <span className="font-weight-bold">{ education.field_of_study }</span>
                                  {
                                    (education.school_name)
                                      ? <span> from</span>
                                      : <></>
                                  }
                                </p>
                              </div>
                              <div className="col">
                                <p className="text-right" style={{ fontSize: '14px', marginBottom: '5px' }}>
                                  <span>{ education.location }</span>
                                  {
                                    (education.location && education.year_of_graduation)
                                      ? <span> - {education.year_of_graduation}</span>
                                      : <></>
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <div className="col">
                                <p style={{ fontSize: '14px', marginBottom: '5px' }}>{ education.school_name }</p>
                              </div>
                            </div>
                          </div>
                        )
                        : <Fragment key={ key }></Fragment>
                    })
                  }
                </div>
              </div>
            </section>
          )
          : <></>
      }
      {
        (data.data?.certifications)
          ? (
            <section className="cv-section">
              <div>
                <p className="cv-section__title">Certifications</p>
                <pre>{ data.data.certifications }</pre>
              </div>
            </section>
          )
          : <></>
      }
      {
        (data.data?.additionalskills)
          ? (
            <section className="cv-section">
              <div>
                <p className="cv-section__title">Additional Skills</p>
                <pre>{ data.data.additionalskills }</pre>
              </div>
            </section>
          )
          : <></>
      }
    </>
  )
}
