import React, {Fragment, FunctionComponent, useContext, useEffect, useState} from 'react';

import {Skill} from '../../../../interfaces/entity/Skill';
import {SkillDiff} from '../../../../interfaces/entity/SkillDiff';
import {SkillEditFormContext, SkillEditFormContextInterface} from './SkillEditForm';
import diffService from '../../../../services/data/DiffService';

import './../../WRSkillTree/work-record-skill-tree.scss';

const makeDiff = (skills: Array<Skill>, formSkills: Array<Skill>, setDiff: Function) => {
  setDiff(diffService.getSkillDiff(skills, formSkills));
}

export const SkillChange: FunctionComponent = () => {
  const { wrSkills, filteredSkills, formSkills } = useContext<SkillEditFormContextInterface>(SkillEditFormContext);
  const [diff, setDiff] = useState<Array<SkillDiff>>([]);

  useEffect(() => {
    makeDiff(wrSkills, formSkills, setDiff);
  }, [formSkills]);

  if (filteredSkills.length && diff.length) {
    return (
      <div className="work-record-skills work-record-skills--change">
        {
          diff.map((dSkill: SkillDiff) => (
            <div
              key={dSkill.skill.id}
              className={
                `work-record-skill work-record-skill--level-${dSkill.skill.hierarchyLevel} clearfix` +
                ` work-record-skill--${dSkill.type}`
              }>
              <div className="work-record-skill__name">{dSkill.skill.getShortedName()}</div>
              <div className="work-record-skill__level">
                {(dSkill.type === 'change')
                  ? `${dSkill.oldLevel} ${dSkill.skill.getSeparator()} ${dSkill.newLevel}`
                  : dSkill.skill.level
                }
              </div>
            </div>
          ))
        }
      </div>
    );
  }

  return <Fragment/>;
}
