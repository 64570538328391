import React from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'

import { PopoverBase } from '../PopoverBase'
import DateHelper from '../../../utils/date/DateHelper'
import ValueHelper from '../../../utils/ValueHelper'

import './popover-attribute-future.scss'

type PropsType = {
  future: Array<any>,
  alias?: string,
  placement?: string
}

const ID = 'popover-attributeFuture'
const TITLE = 'Changes in this field'

export const PopoverAttributeFuture: React.FC<PropsType> = (props: PropsType) => {
  
  if (!props.future.length) return <></>

  return (
    <PopoverBase id={ ID } title={ TITLE } placement={ props.placement || 'right' } btn={ <FA icon={['fas', 'exchange-alt']}/> }>
      <div>
        {
          props.future.map((attribute: any, i: number) =>
            <div key={`future-attribute-${i}`} className="attribute-item">
              <span className="attribute-previous-title">{ ValueHelper.getValue(attribute.previousValue, props.alias ?? attribute.attribute) || 'N/A' }</span>
              <FA icon={['fas', 'long-arrow-alt-right']}/>
              <span className="attribute-future-title">{ ValueHelper.getValue(attribute.value, props.alias ?? attribute.attribute) }</span>
              <p className="attribute-status text-capitalize">
                { (attribute.status === 'approved') && <FA icon={['far', 'check-circle']}/> }
                { (attribute.status === 'pending') && <FA icon={['far', 'clock']}/> }
                <span className="ml-1">{ attribute.status }</span>
              </p>
              <p>
                Requested by: <a href={`/staff/${attribute.created_by.id}/work-records/`}>{ attribute.created_by.full_name }</a>
              </p>
              <p>
                Active from: { DateHelper.getFormattedDate(new Date(attribute.start_date)) }
              </p>
            </div>
          )
        }
      </div>
    </PopoverBase>
  )
}
