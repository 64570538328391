import React, {FC, Fragment, useEffect, useState} from 'react';
import {PopoverBase} from '../PopoverBase';
import {FontAwesomeIcon as FA} from '@fortawesome/react-fontawesome';
import StaffClient from '../../../services/http/StaffClient';

import './popover-line-manager.scss';
import ValueStaff from "../../../models/ValueStaff";

type PropsType = {
  value: ValueStaff | null,
  staffId: number,
  wrId: number
};

const ID = 'popover-lineManager';
const TITLE = 'Further hierarchy';

export const PopoverLineManager: FC<PropsType> = (props: PropsType) => {

  const [lineManagers, setLineManagers] = useState<Array<any>>([]);

  useEffect(() => {
    StaffClient.getParentLMList(props.staffId, props.wrId).then((data: any) => {
      setLineManagers(data);
    })
  }, [props.staffId, props.wrId, props.value]);

  if (lineManagers.length) {
    return (
      <PopoverBase id={ID} title={TITLE} btn={<FA icon={['fas', 'sitemap']}/>}>
        <ul>
          {
            lineManagers.map((lm: any, i: number) =>
              <li key={`lm-${i}`} className="{true: 'unknown-staff-node', false: ''}[lm.unknown]">{lm.name}</li>
            )
          }
        </ul>
        {/*ng-if="currentStaffNode && !currentStaffNode.unknown"*/}
        <div className="view-org-chart">
          <a href={`/structure/lm-tree/staff/${props.staffId}/work-record/${props.wrId}`}
             target="_blank">
            view org chart
          </a>
        </div>
      </PopoverBase>
    );
  }
  return <Fragment/>
};
