export const ATTRIBUTE_NAMES = {
  profile: {
    'username': 'Username',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'middleName': 'Middle Name',
    'dateOfBirth': 'Date of Birth',
    'gender': 'Gender',
    'email': 'Work email',
    'skype': 'Skype',
    'phone1': 'Mobile Phone #1',
    'phone2': 'Mobile Phone #2',
    'emergContactName': 'Emergency Contact Name',
    'emergContactPhone': 'Emergency Contact Phone',
    'facebook': 'Facebook',
    'linkedIn': 'LinkedIn',
    'wiki': 'Wiki',
    'companyStartDay': 'Company start date',
    'alumni': 'Alumni',
    'applyChanges': 'Apply This Change From',
    'physicalLocation': 'Current Location'
  },
  wrMain: {
    'dates': 'Dates',
    'seniority': 'Seniority',
    'mentor': 'Mentor',
    'lineManager': 'Line Manager',
    'isBillable': 'Is Billable',
    'billingLevel': 'Billing Level',
    'maxTotalLoad': 'Max Total Load',
    'maxBillableLoad': 'Max Billable Load',
    'performance': 'Performance',
    'payments': 'Payments',
    'bonus': 'Bonus',
    'unit': 'Unit',
    'orgStructure': 'Star Unit',
    'orgStructureManager': 'Unit Manager',
    'department': 'Department',
    'departmentManager': 'Department Manager',
    'subDepartment': 'Sub-Department',
    'subDepartmentManager': 'Sub-Department Manager',
    'location': 'Location',
    'costLocation': 'Cost Location',
    'position': 'Position',
    'jiraRecId': 'Recruitment Profile',
    'recruitmentId': 'Recruitment Profile',
    'employment': 'Empl. Type',
    'skill': 'Skill',
    'turnover': 'Turnover',
    'feedback': 'Feedback',
    'peoplePartner': 'People Partner',
    'cv': 'CV',
    'grLevel': 'Consultancy Ranking',
    'cohort': 'Cohort',
    'reasonToLeave': 'Reason To Leave',
    'nextEmployer': 'Next Employer',
    'trialStatus': 'Trial Status',
    'trialEndDate': 'Trial End Date',
    'tenure': 'Tenure',
    'costCenter': 'Cost Center'
  },
  wrMainStaff: {
    'email': 'Work email',
    'skype': 'Skype',
    'phone1': 'Phone 1',
    'phone2': 'Phone 2',
    'facebook': 'Social',
    'wiki': 'Wiki',
    'dateOfBirth': 'Date of Birth',
    'gender': 'Gender',
    'emergContactName': 'Emergency Contact Name',
    'emergContactPhone': 'Emergency Contact Phone',
    'physicalLocation': 'Physical Location',
    'username': 'Username'
  },
  wrPositionDescription: {
    'employment': 'Employment Type',
    'startDate': 'Start Date',
    'endDate': 'End Date',
    'companyStartDate': 'Company Start Date',
    'position': 'Position',
    'seniority': 'Seniority',
    'unit': 'Org. Unit',
    'location': 'Location',
    'costLocation': 'Cost Location',
    'isBillable': 'Is Billable',
    'billingLevel': 'Billing Level',
    'lineManager': 'Line Manager',
    'mentor': 'Mentor',
    'trialPeriod': 'Trial Period',
    'trialEndDate': 'Trial End Date',
    'jiraRecId': 'Jira rec',
    'recruitmentId': 'Recruitment ID',
    'peoplePartner': 'People Partner',
    'grLevel': 'Consultancy Ranking',
    'cohort': 'Cohort',
    'maxTotalLoad': 'Max Total Load',
    'maxBillableLoad': 'Max Billable Load',
    'applyChanges': 'Apply This Change From',
    'costCenter': 'Cost Center'
  },
  wrTurnover: {
    'turnover': 'Turnover',
  },
  wrTrialStatus: {
    'trialStatus': 'Trial Status',
    'trialEndDate': 'Trial End Date'
  },
  wrReasonToLeave: {
    'reasonToLeave': 'Reason to Leave',
  },
  wrNextEmployer: {
    'nextEmployer': 'Next Employer',
  },
  wrSkills: {
    'common': 'Skill or subject area',
    'hardSkills': 'Top Hard Skills',
    'softSkills': 'Top Soft Skills',
    'languageSkills': 'Languages',
  },
  activateWorkRecord: {
    'startDate': 'Start Date',
    'endDate': 'End Date',
  },
  closeWorkRecord: {
    'startDate': 'Start Date',
    'endDate': 'End Date',
    'turnover': 'Turnover',
    'trialStatus': 'Trial Status',
    'trialEndDate': 'Trial End Date',
    'reasonToLeave': 'Reason to Leave',
    'nextEmployer': 'Next Employer',
  },
  educationItem: {
    'fieldOfStudy': 'Field of study',
    'degree': 'Degree',
    'schoolName': 'School Name',
    'location': 'Location',
    'yearOfGraduation': 'Year of graduation',
  },
  feedbackItem: {
    'type': 'Feedback Type',
    'reviewer': 'Reviewer',
    'periodStart': 'Period Start',
    'periodEnd': 'Period End',
    'project': 'Project',
    'projectManager': 'Project Manager',
  }
};
