import React, { createContext, useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { ErrorMessage, Form, Formik } from 'formik';

// store
import store from '../../../../store/store';
import { setShouldUpdate } from '../../../../store/workRecord/actions';
import { loaderActions } from '../../../../store/loader/actions';
import { dispatchIndexedPopupState } from '../../../../store/popup/types';

import dictionaryStore from '../../../../services/dictionary/DictionaryStore';
import wrHelper from '../../../../services/data/WorkRecordHelper';

import mapper from '../../../../mappers/response/SkillMapper';

import { WorkRecord } from '../../../../interfaces/WorkRecord';

import { Skill as SkillInterface, Skill } from '../../../../interfaces/entity/Skill';

import { DateField } from '../../../Forms/DateField';
import { SkillEditFormFields } from './SkillEditFormFields';
import { SkillEditFormSearchFields } from './SkillEditFormSearchFields';
import { PopupIndexedCloser } from '../../../Popup/PopupIndexedCloser';
import { TextInput } from '../../../Forms/Standalone/TextInput';
import { SkillSidebar } from './SkillSidebar';
import { SkillChange } from './SkillChange';
import { SkillPreview } from './SkillPreview';

import SkillEditState, { SkillEditStateInterface } from './SkillEditState';
import { schema } from '../Skill/SkillFormSchema';

import './skill-edit-wrapper.scss';

interface SkillEditFormProps {
  title: string
  wrId: number
  wrSkills: Array<Skill>
  workRecord: WorkRecord
  type: 'hardSkills' | 'softSkills' | 'languageSkills'
  popupName: string
  popupIndex: string
}

export interface SkillEditFormContextInterface {
  type: 'hardSkills' | 'softSkills' | 'languageSkills',
  skills: Array<SkillInterface>,
  filteredSkills: Array<SkillInterface>,
  setFilteredSkills: Function,
  wrSkills: Array<SkillInterface>,
  formSkills: Array<SkillInterface>,
  setFormSkills: Function
}

export const SkillEditFormContext = createContext<SkillEditFormContextInterface>({
  type: 'hardSkills',
  skills: [],
  filteredSkills: [],
  setFilteredSkills: () => {},
  wrSkills: [],
  formSkills: [],
  setFormSkills: () => {},
});

export const SkillEditForm: React.FC<SkillEditFormProps> = (props: SkillEditFormProps) => {
  const { title, wrId, wrSkills, workRecord, type, popupName, popupIndex } = { ...props };
  
  const [initialState, setInitialState] = useState<SkillEditStateInterface | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [skills, setSkills] = useState<Array<SkillInterface>>([]);
  const [filteredSkills, setFilteredSkills] = useState<Array<SkillInterface>>([]);
  const [formSkills, setFormSkills] = useState<Array<SkillInterface>>([]);
  const [query, setQuery] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('edit');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  
  const [minApplyDate, setMinApplyDate] = useState<Date | null>(null);
  const [maxApplyDate, setMaxApplyDate] = useState<Date | null>(null);

  const filterSkills = (query: string) => {
    setQuery(query);
    if (query.length > 1) {
      query = query.toLowerCase();
      const filtered = skills.filter((skill: SkillInterface) => skill.name.toLowerCase().indexOf(query) !== -1);
      setFilteredSkills(filtered);
      setActiveTab('search')
    } else {
      if (filteredSkills.length !== skills.length) {
        setFilteredSkills(skills);
      }
      setActiveTab('edit');
    }
  };

  const changeTab = (key: string | null) => {
    if (key) {
      setActiveTab(key);
      if (key !== 'search') {
        setQuery('');
        setFilteredSkills(skills);
      }
    }
  };

  const submitFunction = (values: any) => {
    setIsSaving(true);
    store.dispatch(loaderActions.showLoader());
    wrHelper.updateSkills(wrId, wrSkills, values)
      .then((data: any) => {
        store.dispatch(setShouldUpdate(true));
        dispatchIndexedPopupState(popupName, popupIndex, false);
      })
      .finally(() => {
        setIsSaving(false);
        store.dispatch(loaderActions.hideLoader());
      });
  };

  useEffect(() => {
    if (initialState == null && workRecord) {
      setInitialState(SkillEditState.generateFromList(wrSkills, workRecord));
      setFormSkills(wrSkills);
      if (workRecord.startDate) {
        setMinApplyDate(workRecord.startDate);
      }
      if (workRecord.endDate) {
        setMaxApplyDate(workRecord.endDate);
      }
    }
    if (!isLoaded) {
      dictionaryStore.getByAlias('skill').then((data: any) => {
        if (typeof data !== 'undefined') {
          const beSkills: Array<SkillInterface> = mapper.convertSkillsToSet(data)[type];
          setSkills(beSkills);
          setFilteredSkills(beSkills);
          setIsInitialized(true);
        }
      });
      setIsLoaded(true);
    }
  }, [initialState, workRecord])

  if (initialState && isInitialized) {
    return (
      <SkillEditFormContext.Provider value={{
        type: type,
        skills: skills,
        filteredSkills: filteredSkills,
        setFilteredSkills: setFilteredSkills,
        wrSkills: wrSkills,
        formSkills: formSkills,
        setFormSkills: setFormSkills
      }}>
        <Formik
          initialValues={ initialState }
          validationSchema={schema}
          onSubmit={values => {
            submitFunction(values);
          }}>
          {({errors, touched, values}) => (
            <Form className="edb-form form-edit-skill">
              <div className="row skill-edit-wrapper">
                <div className="col-auto col-no-padding skill-sidebar">
                  <div className="skill-sidebar__title">
                    {title}
                  </div>
                  <div className="skill-sidebar__body">
                    <SkillSidebar/>
                  </div>
                </div>
                <div className="col col-no-left-padding">
                  <Tab.Container activeKey={ activeTab } onSelect={(key: string | null) => changeTab(key)}>
                    <div className="skill-header clearfix">
                      <Nav className="tabs-control clearfix">
                        <Nav.Link className="tabs-control__button" eventKey="edit">Edit Mode</Nav.Link>
                        <Nav.Link className="tabs-control__button" eventKey="changes">Changes</Nav.Link>
                        <Nav.Link className="tabs-control__button" eventKey="preview">Preview</Nav.Link>
                      </Nav>
                      <div className="search">
                        <TextInput
                          value={query}
                          name="search"
                          placeholder="Search"
                          isClearable={true}
                          changeEffect={filterSkills}/>
                      </div>
                    </div>
                    <div className="skill-content">
                      <Tab.Content>
                        <Tab.Pane eventKey="edit">
                          {(query.length === 0) ? <SkillEditFormFields valuesSkills={ values.skills } /> : '' }
                        </Tab.Pane>
                        <Tab.Pane eventKey="search">
                          {(query.length !== 0) ? <SkillEditFormSearchFields valuesSkills={ values.skills } /> : '' }
                        </Tab.Pane>
                        <Tab.Pane eventKey="changes">
                          <SkillChange/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="preview">
                          <SkillPreview/>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                  <div className="skill-footer">
                    <div className="row">
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="activeFrom">Active From</label>
                          <DateField
                            autoComplete='none'
                            id="activeFrom"
                            name="activeFrom"
                            className="form-control"
                            popperPlacement="top-start"
                            onChange={() => {}}
                            minDate={ minApplyDate }
                            maxDate={ maxApplyDate }
                          />
                          <ErrorMessage name="activeFrom"/>
                        </div>
                      </div>
                      <div className="col-6 offset-3 form-buttons">
                        <PopupIndexedCloser popupName={popupName} popupIndex={popupIndex}>
                          <button className="edb-btn edb-btn--secondary" type="reset">
                            Cancel
                          </button>
                        </PopupIndexedCloser>
                        <button
                          type="submit"
                          className="edb-btn edb-btn--primary"
                          disabled={ isSaving }
                        >
                          { isSaving ? 'Saving' : 'Save' }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </SkillEditFormContext.Provider>
    );
  }
  return <></>;
};
