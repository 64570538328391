import React, {FunctionComponent, useEffect, useState} from 'react';
import {Staff} from "../../../../interfaces/Staff";

import './my-team-table-short-info.scss';
import {Spinner} from "../../../Spinner/Spinner";
import {MyTeamType} from "../../../Forms/Standalone/MyTeamType";

interface MyTeamTableShortInfoProps {
  myStaff: Staff,
  myTeamEmployCount: number,
  directReportsCount: number,
  isLoading: boolean,
  myTeamType: string,
  changeMyTeamType: Function,
  setInfoHeight: Function
}

export const MyTeamTableShortInfo: FunctionComponent<MyTeamTableShortInfoProps> = (props: MyTeamTableShortInfoProps) => {
  const {myStaff, myTeamEmployCount, directReportsCount, isLoading, myTeamType, changeMyTeamType} = {...props};

  if (!isLoading) {
    return (
        <div className="table-short-info-wrapper" ref={ el => props.setInfoHeight(el ? el.getBoundingClientRect().height : 0) }>
          <div className="row">
            <div className="col">
              <span className="my-team-greeting">Hello, {myStaff.firstName.value} {myStaff.lastName.value}</span>
              <span className="my-team-description">
              You have a team of
              <span className="my-team-team-members-qty"> {myTeamEmployCount}</span> people
            </span>
            </div>
            <div className="col">
              <MyTeamType
                  value={myTeamType}
                  setter={changeMyTeamType}
                  myTeamEmployCount={myTeamEmployCount}
                  directReportsCount={directReportsCount}
              />
            </div>
          </div>
        </div>
    );
  }

  return <Spinner/>;
}
