import React, {FC, useState, PropsWithChildren, cloneElement, ReactElement} from 'react';
import { Popover, Overlay } from 'react-bootstrap';

import './popover-base.scss';
import useOutsideClick from "../../hooks/outsideClick";

type PropsType = {
  id: string,
  title: string,
  btn: ReactElement,
  placement?: string
}

export const PopoverBase: FC<PropsType> = (props: PropsWithChildren<PropsType>) => {
  const [target, setTarget] = useState(null);

  // const refPopover = useRef(null);
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);

  const handleClick = (e: any) => {
    setIsVisible(!isVisible);
    setTarget(e.target);
  };
  
  return (
    <div ref={ref} className="popover-base">
      { cloneElement(props.btn as ReactElement, {onClick: handleClick}) }
      <Overlay
        show={isVisible}
        target={target}
        // @ts-ignore
        placement={ props.placement || 'right' }
        // container={ref.current}
        containerPadding={20}>
        <Popover id={props.id}>
          <Popover.Title>{props.title}</Popover.Title>
          <Popover.Content>
            {props.children}
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};
