import moment from 'moment'

import { FeedbackI } from '../../../../interfaces/entity/Feedback'
import { SelectValueInterface, defaultSelectValue, SelectKeyValueI, defaultSelectKeyValue } from '../../../../interfaces/form/SelectValueInterface'
import { WorkRecord } from '../../../../interfaces/WorkRecord'
import { Staff } from '../../../../interfaces/Staff'
import { defaultFeedbackProjectValue, FeedbackProject } from '../../../../interfaces/entity/FeedbackProject'

export interface FeedbackItemStateInterface {
  id: number;
  type: SelectKeyValueI;
  reviewer: SelectValueInterface;
  periodStart: Date;
  periodEnd: Date;
  project?: FeedbackProject;
  projectManager?: SelectValueInterface;
  value: string;
}
const periodStart = new Date();
periodStart.setDate(periodStart.getDate() - 90);
const periodEnd = new Date();

const initialState: FeedbackItemStateInterface = {
  id: NaN,
  type: defaultSelectKeyValue,
  value: '',
  reviewer: defaultSelectValue,
  periodStart: periodStart,
  periodEnd: periodEnd,
  project: defaultFeedbackProjectValue,
  projectManager: defaultSelectValue
};

export default class FeedbackItemState {
  public static generateFromFeedback(workRecord: WorkRecord, myStaff: Staff, feedback?: FeedbackI): FeedbackItemStateInterface {
    let state = { ...initialState }
    if (workRecord?.startDate && workRecord.startDate > state.periodStart) state.periodStart = workRecord.startDate
    if (feedback) {
      state.id = feedback.id;
      state.type = (feedback.type) ? { id: feedback.type.alias, name: feedback.type.title } : defaultSelectKeyValue;
      state.value = feedback.value;
      const author = feedback.author;
      state.reviewer = (author?.id) ? { id: author.id, name: author.fullName } : FeedbackItemState.getReviewerByFeedbackType(state.type.id, workRecord, myStaff);
      state.periodStart = (feedback.periodStart) ? moment(feedback.periodStart).toDate() : state.periodStart;
      state.periodEnd = (feedback.periodEnd) ? moment(feedback.periodEnd).toDate() : state.periodEnd;
      state.project = (feedback.project) ? feedback.project : defaultFeedbackProjectValue;
      const pm = feedback.projectManager;
      state.projectManager = (pm && pm.id) ? {id: pm.id, name: pm.fullName} : defaultSelectValue;
    }
    return state;
  }

  public static getReviewerDefault(workRecord: WorkRecord) {
    if (workRecord.peoplePartner) {
      return {
        id: workRecord.peoplePartner.staffId as number,
        name: workRecord.peoplePartner.staffFullName
      }
    }
    return defaultSelectValue;
  }

  public static getReviewerLineManager(workRecord: WorkRecord) {
    if (workRecord.lineManager) {
      return {
        id: workRecord.lineManager.staffId as number,
        name: workRecord.lineManager.staffFullName
      }
    }
    return defaultSelectValue;
  }

  public static getReviewerCurrentUser(staff: Staff) {
    if (staff) {
      return {
        id: staff.id as number,
        name: staff.fullName
      }
    }
    return defaultSelectValue;
  }

  public static getReviewerByFeedbackType(type: string = '', workRecord: WorkRecord, myStaff: Staff) {
    if (!workRecord) {
      return defaultSelectValue;
    }
    switch (type) {
      case 'lm_summary':
        return FeedbackItemState.getReviewerLineManager(workRecord);
      case 'pitstop':
      case 'client_feedback':
        return FeedbackItemState.getReviewerCurrentUser(myStaff);
      default:
        return FeedbackItemState.getReviewerDefault(workRecord);
    }
  }
}
