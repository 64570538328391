export const ATTRIBUTES_MAP: any = {
  staff: {
    'email': 'email',
    'skype': 'skype',
    'phone1': 'phone1',
    'phone2': 'phone2',
    'linkedin': 'linkedIn',
    'facebook': 'facebook',
    'wiki': 'wiki',
    'aboutme': 'aboutMe',
    'first_name': 'firstName',
    'last_name': 'lastName',
    'middle_name': 'middleName',
    'date_of_birth': 'dateOfBirth',
    'gender': 'gender',
    'emerg_contact_name': 'emergContactName',
    'emerg_contact_phone': 'emergContactPhone',
    'physical_location': 'physicalLocation'
  },
  workRecord: {
    'seniority': 'seniority',
    'mentor': 'mentor',
    'line_manager': 'lineManager',
    'is_billable': 'isBillable',
    'billing_level': 'billingLevel',
    'max_total_load': 'maxTotalLoad',
    'max_billable_load': 'maxBillableLoad',
    'performance': 'performance',
    'payments': 'payments',
    'bonus': 'bonus',
    'unit': 'unit',
    'location': 'location',
    'cost_location': 'costLocation',
    'position': 'position',
    'jira_rec_id': 'jiraRecId',
    'recruitment_id': 'recruitmentId',
    'employment': 'employment',
    'skill': 'skill',
    'turnover': 'turnover',
    'feedback': 'feedback',
    'people_partner': 'peoplePartner',
    'cv': 'cv',
    'gr_level': 'grLevel',
    'reason_to_leave': 'reasonToLeave',
    'next_employer': 'nextEmployer',
    'cost_center': 'costCenter',
    'cohort': 'cohort'
  },
  staffBackend: {
    'email': 'email',
    'skype': 'skype',
    'phone1': 'phone1',
    'phone2': 'phone2',
    'linkedIn': 'linkedin',
    'facebook': 'facebook',
    'wiki': 'wiki',
    'aboutMe': 'aboutme',
    'firstName': 'first_name',
    'lastName': 'last_name',
    'middleName': 'middle_name',
    'dateOfBirth': 'date_of_birth',
    'gender': 'gender',
    'emergContactName': 'emerg_contact_name',
    'emergContactPhone': 'emerg_contact_phone',
    'physicalLocation': 'physical_location'
  },
  workRecordBackend: {
    'seniority': 'seniority',
    'mentor': 'mentor',
    'lineManager': 'line_manager',
    'isBillable': 'is_billable',
    'billingLevel': 'billing_level',
    'maxTotalLoad': 'max_total_load',
    'maxBillableLoad': 'max_billable_load',
    'performance': 'performance',
    'payments': 'payments',
    'bonus': 'bonus',
    'unit': 'unit',
    'location': 'location',
    'costLocation': 'cost_location',
    'position': 'position',
    'jiraRecId': 'jira_rec_id',
    'recruitmentId': 'recruitment_id',
    'employment': 'employment',
    'skill': 'skill',
    'turnover': 'turnover',
    'feedback': 'feedback',
    'peoplePartner': 'people_partner',
    'cv': 'cv',
    'grLevel': 'gr_level',
    'reasonToLeave': 'reason_to_leave',
    'nextEmployer': 'next_employer',
    'costCenter': 'cost_center',
    'cohort': 'cohort'
  },
  workRecordProperties: {
    'trialStatus': 'trial_status',
    'trialEndDate': 'trial_end_date',
  },
  feedbackItem: {
    'type': 'type',
    'reviewer': 'reviewer',
    'period_start': 'periodStart',
    'period_end': 'periodEnd',
    'project': 'project',
    'project_manager': 'projectManager'
  }
};

