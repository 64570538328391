import {defaultSelectKeyValue, defaultSelectValue} from '../../../../interfaces/form/SelectValueInterface';
import {PositionDescriptionStateInterface} from '../PositionDescription/PositionDescriptionState';
import {SkillStateInterface} from '../Skill/SkillState';

export interface AddWorkRecordStateInterface extends PositionDescriptionStateInterface, SkillStateInterface {
}

const initialState: AddWorkRecordStateInterface = {
  employment: defaultSelectValue,
  startDate: null,
  endDate: null,
  position: defaultSelectValue,
  seniority: defaultSelectKeyValue,
  unit: defaultSelectValue,
  location: defaultSelectValue,
  costLocation: defaultSelectValue,
  isBillable: false,
  billingLevel: defaultSelectValue,
  lineManager: defaultSelectValue,
  mentor: defaultSelectValue,
  trialPeriod: false,
  trialEndDate: null,
  jiraRecId: '',
  recruitmentId: '',
  peoplePartner: defaultSelectValue,
  grLevel: defaultSelectValue,
  maxTotalLoad: 0,
  maxBillableLoad: 0,
  costCenter: defaultSelectValue,
  cohort: defaultSelectValue,

  hardSkills: [],
  softSkills: [],
  languageSkills: [],

  //TODO Refactor
  applyChanges: new Date(),
}

export default class AddWorkRecordState {
  public static generateFromWRnSkills(positionState: PositionDescriptionStateInterface, skillState: SkillStateInterface):
    AddWorkRecordStateInterface {
    let state = initialState;
    if (positionState) {
      Object.keys(positionState).forEach((key) => {
        //@ts-ignore
        state[key] = positionState[key];
      });
    }
    if (skillState) {
      Object.keys(skillState).forEach((key) => {
        //@ts-ignore
        state[key] = skillState[key];
      });
    }
    return state;
  }
}
