import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorMessage, Field, FormikErrors, FormikTouched, FormikValues } from 'formik';

import { GlobalState } from '../../../../store/types';
import { STAFF_STATE } from '../../../../store/reducers';

import { Staff } from '../../../../interfaces/Staff';
import dictionaryStore from '../../../../services/dictionary/DictionaryStore';
import permissionService from '../../../../services/security/PermissionStaffService';
import DictionaryClient from '../../../../services/http/DictionaryClient';

import { SelectFieldCustom } from '../../../Forms/SelectFieldCustom';
import { ImageField } from '../../../Forms/ImageField';
import { DateField } from '../../../Forms/DateField';
import { PhoneField } from '../../../Forms/PhoneField';
import { SwitcherField } from '../../../Forms/SwitcherField';
import { PopoverAttributeFuture } from '../../../Popovers/PopoverAttributeFuture/PopoverAttributeFuture';

import { ATTRIBUTE_NAMES, COMMON } from '../../../../constants';

const labels = ATTRIBUTE_NAMES['profile'];

const MODE_STAFF_ADD = 'MODE_STAFF_ADD';
const MODE_STAFF_EDIT = 'MODE_STAFF_EDIT';
type ModeType = typeof MODE_STAFF_ADD | typeof MODE_STAFF_EDIT;

interface ProfileFormFieldsProps {
  values: any;
  staff: Staff;
  mode: ModeType;
  formErrors: Array<string>;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  setFieldValue: Function;
}

export const ProfileFormFields: React.FC<ProfileFormFieldsProps> = (props: ProfileFormFieldsProps) => {
  const { values, staff, formErrors, setFieldValue } = { ...props };
  permissionService.setParams(useParams());
  permissionService.setUser(useSelector((state: GlobalState) => state[STAFF_STATE].myStaff));

  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [countries, setCountries] = useState<Array<string>>([]);
  const [cities, setCities] = useState<Array<string>>([]);
  const [cityDisabled, setCityDisabled] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      dictionaryStore.getUsers().then((data: any) => {
        if (typeof data !== 'undefined') {
          setUsers(data);
        }
      });
      DictionaryClient.getCountryList()
        .then((data: any) => {
          if (data.data) {
            const list = data.data.sort();
            setCountries(list);
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
      setIsLoaded(true);
    }
    return () => {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (values.country === COMMON.NOT_SPECIFIED || values.country === '') {
      setFieldValue('city', '');
      setCityDisabled(true);
    } else {
      setCityDisabled(false);
      if (countries.includes(values.country)) {
        DictionaryClient.getCityList(values.country)
          .then((data: any) => {
            setCities(data.data);
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    }
  }, [values.country, countries]);

  const checkValidationClassName = (fieldName: string) => {
    return (props.errors[fieldName] && props.touched[fieldName]) || formErrors.includes(fieldName) ? ' invalid-field' : '';
  };

  return (
    <>
      <div className="staff-form--information">
        {
          (props.mode === MODE_STAFF_EDIT) ? <p className="staff-form--title">Personal Information</p> : <></>
        }
        <div className="form-row">
          <div className="form-group col px-2">
            <ImageField
              id="photo"
              name="photo"
              disabled={!permissionService.isGranted('photo', 'edit', staff)}
              buttonText="Upload photo"
              value={values.photo}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['firstName']} <span className="text-danger">*</span></label>
            {
              staff.attributes.get('firstName')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('firstName').future}/>
                : ''
            }
            <Field
              className={ `form-control${checkValidationClassName('firstName')}` }
              disabled={ !permissionService.isGranted('first_name', 'edit', staff) }
              type="text"
              id="firstName"
              name="firstName"
              data-testid="formStaffFirstName"
            />
          </div>
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['middleName']}</label>
            {
              staff.attributes.get('middleName')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('middleName').future}/>
                : ''
            }
            <Field
              className={ `form-control${checkValidationClassName('middleName')}` }
              disabled={!permissionService.isGranted('middle_name', 'edit', staff)}
              type="text"
              id="middleName"
              name="middleName"
            />
          </div>
          <div className="form-group col px-2">
            <label className="staff-form--label">{ labels['lastName'] } <span className="text-danger">*</span></label>
            {
              staff.attributes.get('lastName')?.future
                ? <PopoverAttributeFuture future={ staff.attributes.get('lastName').future } />
                : ''
            }
            <Field
              className={ `form-control${checkValidationClassName('lastName')}` }
              disabled={ !permissionService.isGranted('last_name', 'edit', staff) }
              type="text"
              id="lastName"
              name="lastName"
              data-testid="formStaffLastName"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col px-2">
            <label className="staff-form--label">{ labels['dateOfBirth'] }</label>
            {
              staff.attributes.get('dateOfBirth')?.future
                ? <PopoverAttributeFuture future={ staff.attributes.get('dateOfBirth').future } />
                : ''
            }
            <DateField
              className="form-control"
              disabled={ !permissionService.isGranted('date_of_birth', 'edit', staff) }
              name="dateOfBirth"
              autoComplete='off'
              popperPlacement="bottom-start"
              onChange={ () => {} }
            />
          </div>
          <div className="form-group col px-2">
            <legend className="col-form-label staff-form--label">
              { labels['gender'] }
              {
                staff.attributes.get('gender')?.future
                  ? <PopoverAttributeFuture future={ staff.attributes.get('gender').future } />
                  : ''
              }
            </legend>
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                disabled={ !permissionService.isGranted('gender', 'edit', staff) }
                id="genderMale"
                name="gender"
                type="radio"
                value="male"
              />
              <label className="form-check-label" htmlFor="genderMale">Male</label>
            </div>
            <div className="form-check form-check-inline pl-4">
              <Field
                className="form-check-input"
                disabled={ !permissionService.isGranted('gender', 'edit', staff) }
                id="genderFemale"
                name="gender"
                type="radio"
                value="female"
              />
              <label className="form-check-label" htmlFor="genderFemale">Female</label>
            </div>
          </div>
          <div className="form-group col px-2"></div>
        </div>
      </div>

      <div className="staff-form--account-details">
        <p className="staff-form--title">Account Details</p>
        <div className="form-row">
          <div className="form-group col px-2">
            <label className="staff-form--label" htmlFor="username">{ labels['username'] }</label>
            <div className="input-group mb-3">
              <Field
                type="text"
                name="username"
                id="username"
                className={ `form-control ${ props.errors.username && props.touched.username ? 'invalid-field' : '' }` }
                disabled={ !permissionService.isGranted('username', 'edit', staff) }
                placeholder="Username"
                aria-label="Username"
                aria-describedby="describeUsername"
              />
                <div className="input-group-append">
                  <span className="input-group-text" id="describeUsername">@star.global</span>
                </div>
            </div>
            <ErrorMessage name="username">{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
          </div>
          <div className="form-group col px-2">
            <label className="staff-form--label">{ labels['email'] }</label>
            {
              staff.attributes.get('email')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('email').future}/>
                : ''
            }
            <Field
              className={ `form-control ${props.errors.email && props.touched.email ? 'invalid-field' : ''}` }
              disabled={ !permissionService.isGranted('email', 'edit', staff) }
              id="email"
              name="email"
              type="email"
            />
            <ErrorMessage name="email">{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
          </div>
        </div>
      </div>

      <div className="staff-form--physical-location">
        <p className="staff-form--title">Physical Location
        {
              staff.attributes.get('physicalLocation')?.future
                ? <PopoverAttributeFuture future={ staff.attributes.get('physicalLocation').future } />
                : ''
            }
        </p>
        <div className="form-row">
          <div className="form-group col px-2">
            <label className="staff-form--label">Country</label>
            
            <SelectFieldCustom
              id="country"
              name="country"
              options={ countries }
              simple={ true }
              isDisabled={ !permissionService.isGranted('physical_location', 'edit', staff) }
              className={ checkValidationClassName('country') }
              data-testid="formStaffCountry"
            />
            <ErrorMessage name="country">{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
          </div>
          <div className="form-group col px-2">
            <label className="staff-form--label">City</label>
            
            <SelectFieldCustom
              id="city"
              name="city"
              options={ cities }
              simple={ true }
              suggestion={ true }
              isDisabled={ cityDisabled || !permissionService.isGranted('physical_location', 'edit', staff) }
              className={ checkValidationClassName('city') }
              data-testid="formStaffCity"
            />
            <ErrorMessage name="city">{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
          </div>
        </div>
      </div>

      <div className="staff-form--contacts">
        <p className="staff-form--title">Contacts</p>
        <div className="form-row">
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['phone1']}</label>
            {
              staff.attributes.get('phone1')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('phone1').future}/>
                : ''
            }
            <PhoneField
              className="form-control"
              disabled={!permissionService.isGranted('phone1', 'edit', staff)}
              id="phone1"
              name="phone1"
              value={values.phone1}
            />
          </div>
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['phone2']}</label>
            {
              staff.attributes.get('phone2')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('phone2').future}/>
                : ''
            }
            <PhoneField
              className="form-control"
              disabled={!permissionService.isGranted('phone2', 'edit', staff)}
              id="phone2"
              name="phone2"
              value={values.phone2}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['emergContactName']}</label>
            {
              staff.attributes.get('emergContactName')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('emergContactName').future}/>
                : ''
            }
            <Field
              className="form-control"
              disabled={!permissionService.isGranted('emerg_contact_name', 'edit', staff)}
              id="emergContactName"
              name="emergContactName"
              type="text"
            />
          </div>
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['emergContactPhone']}</label>
            {
              staff.attributes.get('emergContactPhone')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('emergContactPhone').future}/>
                : ''
            }
            <PhoneField
              className="form-control"
              disabled={!permissionService.isGranted('emerg_contact_phone', 'edit', staff)}
              id="emergContactPhone"
              name="emergContactPhone"
              value={values.emergContactPhone}
            />
          </div>
        </div>
      </div>

      <div className="staff-form--network">
        <p className="staff-form--title">Network</p>
        <div className="form-row">
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['linkedIn']}</label>
            {
              staff.attributes.get('linkedIn')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('linkedIn').future}/>
                : ''
            }
            <Field
              className="form-control"
              disabled={!permissionService.isGranted('linkedin', 'edit', staff)}
              id="linkedIn"
              name="linkedIn"
              type="text"
            />
          </div>
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['facebook']}</label>
            {
              staff.attributes.get('facebook')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('facebook').future}/>
                : ''
            }
            <Field
              className="form-control"
              disabled={!permissionService.isGranted('facebook', 'edit', staff)}
              id="facebook"
              name="facebook"
              type="text"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['skype']}</label>
            {
              staff.attributes.get('skype')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('skype').future}/>
                : ''
            }
            <Field
              className="form-control"
              disabled={!permissionService.isGranted('skype', 'edit', staff)}
              id="skype"
              name="skype"
              type="text"
            />
          </div>
          <div className="form-group col px-2">
            <label className="staff-form--label">{labels['wiki']}</label>
            {
              staff.attributes.get('wiki')?.future
                ? <PopoverAttributeFuture future={staff.attributes.get('wiki').future}/>
                : ''
            }
            <Field
              className="form-control"
              disabled={!permissionService.isGranted('wiki', 'edit', staff)}
              id="wiki"
              name="wiki"
              type="text"
            />
          </div>
        </div>
      </div>

      <div className="staff-form--alumni">
        <p className="staff-form--title">Alumni</p>
        <div className="form-row">
          {
            (values.companyStartDate)
              ? (
                <>
                  <div className="form-group col-4 px-2">
                    <label className="staff-form--label">{labels['companyStartDay']}</label>
                    <DateField
                      className="form-control"
                      id="companyStartDate"
                      name="companyStartDate"
                      autoComplete='off'
                      popperPlacement="top-start"
                      onChange={() => {}}
                    />
                  </div>
                  <div className="form-group col-4 px-2">
                    <label className="staff-form--label">{labels['alumni']}</label>
                    <SwitcherField
                      disabled={!permissionService.isGranted('alumni', 'edit', staff)}
                      id="alumni"
                      name="alumni"
                    />
                  </div>
                </>
              )
              : (
                <div className="form-group col-4 px-2">
                  <label className="staff-form--label">{labels['alumni']}</label>
                  <SwitcherField
                    disabled={!permissionService.isGranted('alumni', 'edit', staff)}
                    id="alumni"
                    name="alumni"
                  />
                </div>
              )
          }
        </div>
      </div>
    </>
  );
}
