import { COMMON } from '../../constants';

const ACTIONS = {
  close: 'close',
  create: 'create'
};

class RequestService {

  public upgradeForCreate(attrs: Array<any>) {
    attrs.forEach((attr) => {
      attr.action = ACTIONS.create;
    });
    return attrs;
  }

  public upgradeForUpdate(attrs: Array<any>) {
    attrs.forEach((attr) => {
      let val;
      if (attr.attribute === 'physical_location') {
        val = attr.value === undefined ? attr.new_value.country_name : attr.value.country_name;
      } else {
        val = attr.value === undefined ? attr.new_value : attr.value;
      }
      if (val === '' || val === null || val === COMMON.NOT_SPECIFIED || Number.isNaN(val) || attr.end_date) {
        attr.action = ACTIONS.close;
        attr.end_date = attr.end_date || attr.apply_from;
        delete attr.apply_from;
        delete attr.value;
        delete attr.new_value;
      } else {
        attr.action = ACTIONS.create;
      }
    });
    return attrs;
  }
}

export default new RequestService();
