import moment from 'moment'

import {
  CheckboxColumnFilter,
  DateColumnFilter,
  SelectColumnFilter,
  TextColumnFilter
} from '../components/Tables/Filters'
import {
  AvatarCell,
  StaffCell,
  StatusCell,
  SkillCell,
  DateCell
} from '../components/Tables/Cells'

const getValue = (value: any) => {
  return value
}

const getBooleanValue = (value: boolean) => {
  return (value) ? 'Yes' : 'No'
}

const getDateValue = (value: string) => {
  if (value) {
    return moment(value).format('MMM DD, YYYY')
  }
}

export const TABLE_CONFIG = {
  employeeTable: {
    code: {
      Filter: TextColumnFilter,
      filter: 'text',
      width: 65
    },
    image_url: {
      order: 1,
      Cell: AvatarCell,
      Filter: '',
      filter: '',
      sortable: false,
      width: 75
    },
    staff: {
      Cell: StaffCell,
      staffId: 'staff_id',
      Filter: TextColumnFilter,
      filter: 'contains',
      width: 250
    },
    position: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 300
    },
    start_date: {
      Cell: DateCell,
      Filter: DateColumnFilter,
      filter: 'betweenDates',
      width: 125
    },
    end_date: {
      Cell: DateCell,
      Filter: DateColumnFilter,
      filter: 'betweenDates',
      width: 125
    },
    org_structure: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 150
    },
    department: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 150
    },
    unit: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 150
    },
    subunit: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 100
    },
    gr_level: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 180
    },
    cohort: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 180
    },
    status: {
      Cell: StatusCell,
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 130
    },
    location: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 180
    },
    cost_location: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 180
    },
    physical_location: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 260
    },
    employment: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 150
    },
    line_manager: {
      Cell: StaffCell,
      staffId: 'line_manager_id',
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 250
    },
    mentor: {
      Cell: StaffCell,
      staffId: 'mentor_id',
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 250
    },
    is_billable: {
      Filter: SelectColumnFilter,
      filter: 'equals',
      width: 110
    },
    max_total_load: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 150
    },
    max_billable_load: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 150
    },
    billing_level: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 130
    },
    seniority: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 110
    },
    skill: {
      Cell: SkillCell,
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      sortable: false
    },
    username: {
      Filter: TextColumnFilter,
      filter: 'text',
      width: 150
    },
    email: {
      Filter: TextColumnFilter,
      filter: 'text',
      width: 150
    },
    people_partner: {
      Cell: StaffCell,
      staffId: 'people_partner_id',
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 140
    },
    trial_status: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 130
    },
    trial_end_date: {
      Cell: DateCell,
      Filter: DateColumnFilter,
      filter: 'betweenDates',
      width: 135
    },
    jira_rec_id: {
      Filter: TextColumnFilter,
      filter: 'text',
      width: 135
    },
    recruitment_id: {
      Filter: TextColumnFilter,
      filter: 'text',
      width: 400
    },
    gender: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 100
    },
    company_start_date: {
      Cell: DateCell,
      Filter: DateColumnFilter,
      filter: 'betweenDates',
      width: 165
    },
    alumni: {
      Filter: SelectColumnFilter,
      filter: 'equals',
      width: 150
    },
    turnover: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 115
    },
    reason_to_leave: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 150
    },
    next_employer: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 125
    },
    date_of_birth: {
      Cell: DateCell,
      Filter: DateColumnFilter,
      filter: 'betweenDates',
      width: 190
    },
    cost_center: {
      Filter: CheckboxColumnFilter,
      filter: 'multiple',
      width: 150
    }
  },
  reportTable: {
    columns: [
      {
        name: 'id',
        label: 'ID',
        type: 'number',
        getValue: getValue,
        configurable: false,
        width: 75
      },
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        getValue: getValue,
        configurable: false,
        width: 150
      },
      {
        name: 'position',
        label: 'Position',
        type: 'string',
        getValue: getValue,
        configurable: false,
        width: 150
      },
      {
        name: 'startDate',
        label: 'Start Date',
        type: 'date',
        getValue: getDateValue,
        configurable: false,
        width: 115
      },
      {
        name: 'endDate',
        label: 'End Date',
        type: 'date',
        getValue: getDateValue,
        configurable: false,
        width: 115
      },
      {
        name: 'orgStructure',
        label: 'Star Unit',
        type: 'string',
        getValue: getValue,
        configurable: false,
        width: 125
      },
      {
        name: 'department',
        label: 'Department',
        type: 'string',
        getValue: getValue,
        configurable: false,
        width: 150
      },
      {
        name: 'subDepartment',
        label: 'Sub-Department',
        type: 'string',
        getValue: getValue,
        configurable: false,
        width: 150
      },
      {
        name: 'location',
        label: 'Location',
        type: 'string',
        getValue: getValue,
        configurable: false,
        width: 125
      },
      {
        name: 'lineManager',
        label: 'Line Manager',
        type: 'string',
        getValue: getValue,
        configurable: false,
        width: 150
      },
      {
        name: 'isBillable',
        label: 'Billable',
        type: 'boolean',
        getValue: getBooleanValue,
        configurable: false,
        width: 110
      },
      {
        name: 'turnover',
        label: 'Turnover',
        type: 'string',
        getValue: getValue,
        configurable: true,
        width: 125
      },
      {
        name: 'reasonToLeave',
        label: 'Reason To Leave',
        type: 'string',
        getValue: getValue,
        configurable: true,
        width: 160
      },
      {
        name: 'nextEmployer',
        label: 'Next Employer',
        type: 'string',
        getValue: getValue,
        configurable: true,
        width: 160
      }
    ]
  }
}

export const ALL_EMPLOYEE_FILTERS = {
  employment: 'employment',
  location: 'location',
  isBillable: 'is_billable',
  orgStructure: 'org_structure',
  department: 'department',
  unit: 'unit',
  subunit: 'subunit'
}
