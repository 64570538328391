import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';

import store from '../../../store/store';
import { MY_TEAM_TABLE_STATE } from '../../../store/reducers';
import dictionaryClient from './../../../services/http/DictionaryClient';
import { setMtDirectAttributes, setMtSubordinatesAttributes } from '../../../store/myTeamTable/actions';
import EmployeeTableHelper from '../../../services/data/EmployeeTableHelper';

import { MyTeamTable } from './MyTeamTable/MyTeamTable';
import { Spinner } from '../../Spinner/Spinner';

export const MyTeamTableWrapper: React.FC = () => {
    const {getState} = useStore();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [subordinatesColumns, setSubordinatesColumns] =
        useState<Array<any>>(getState()[MY_TEAM_TABLE_STATE].subordinatesAttributes);
    const [directColumns, setDirectColumns] = useState<Array<any>>(getState()[MY_TEAM_TABLE_STATE].directAttributes);

    useEffect(() => {
        if (isLoaded) {
            return;
        }
        const subordinatesPromise: Promise<any> = dictionaryClient.getSubordinatesAllAttribute()
            .then((data) => {
                const convertedSubordinatesCols = EmployeeTableHelper.convertAttributesToColumns(data.columns);
                setSubordinatesColumns(convertedSubordinatesCols);
                store.dispatch(setMtSubordinatesAttributes(convertedSubordinatesCols));
            });
        const directPromise: Promise<any> = dictionaryClient.getDirectAllAttribute().then((data) => {
            const convertedDirectCols = EmployeeTableHelper.convertAttributesToColumns(data.columns);
            setDirectColumns(convertedDirectCols);
            store.dispatch(setMtDirectAttributes(convertedDirectCols));
        });

        Promise.all([subordinatesPromise, directPromise]).then(() => {
            setIsLoaded(true);
        });
    }, [isLoaded]);

    if (isLoaded) return <MyTeamTable subordinatesColumns={ subordinatesColumns } directColumns={ directColumns } />

    return <Spinner />;
}
