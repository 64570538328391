import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import store from '../../../store/store';
import { GlobalState } from '../../../store/types';
import { setActiveId, setShouldUpdate, setWorkRecord } from '../../../store/workRecord/actions';
import { STAFF_STATE, WORK_RECORD_STATE } from '../../../store/reducers';

import client from '../../../services/http/WorkRecordClient';
import WorkRecordClient from '../../../services/http/WorkRecordClient';

import WorkRecord from '../../../models/WorkRecord';

import mapper from '../../../mappers/response/SkillMapper';
import WorkRecordMapper from '../../../mappers/response/WorkRecordMapper';

import { SkillSetInterface } from '../../../interfaces/SkillSetInterface';
import { WorkRecord as WorkRecordInterface } from '../../../interfaces/WorkRecord';

import { Popup } from '../../Popup/Popup';
import { PopupTrigger } from '../../Popup/PopupTrigger';
import { PositionDescriptionUpdateWrapper } from '../Forms/PositionDescription/PositionDescriptionUpdateWrapper';
import { ActivateWorkRecordForm } from '../Forms/ActivateWorkRecord/ActivateWorkRecordForm';
import { CloseWorkRecordForm } from '../Forms/CloseWorkRecord/CloseWorkRecordForm';
import { MODE_VIEW, WRAttributes } from '../WRAttributes/WRAttributes';
import { WRSkill } from '../WRSkillTree/WRSkill';
import { ElementWithPermission } from '../../ElementWithPermission/ElementWithPermission';

import { STATUSES } from '../../../constants';

import './work-record-main.scss';

interface WRMainParams {
  staffId: string;
  wrId: string;
}

export const WRMain: React.FC = () => {
  const params = useParams<WRMainParams>();
  const cParams = {
    staffId: Number(params.staffId),
    wrId: Number(params.wrId)
  };

  const { staff, shouldUpdate: staffForceUpdate } = useSelector((state: GlobalState) => state[STAFF_STATE]);
  const { mainId, workRecord, shouldUpdate: wrForceUpdate } = useSelector((state: GlobalState) => state[WORK_RECORD_STATE]);

  const history = useHistory();

  useEffect(() => {
    if (staffForceUpdate) return
    
    if (cParams?.staffId === staff?.id && cParams?.wrId !== workRecord?.id) {
      let wr: WorkRecord = staff.workRecords.filter((wr: WorkRecordInterface) => wr.id === cParams.wrId)[0];
      if (!wr) {
        store.dispatch(setActiveId(mainId));
        history.replace(`/staff/${staff.id}/work-records/${mainId}/main`);
        return;
      }
      store.dispatch(setWorkRecord(wr));
      store.dispatch(setActiveId(cParams.wrId));
    }
  }, [cParams.wrId, staff, workRecord, staffForceUpdate]);

  const [skills, setSkills] = useState<SkillSetInterface>({hardSkills: [], softSkills: [], languageSkills: []});

  useEffect(() => {
    if (workRecord) {
      client.getSkills(workRecord.id).then((data: any) => {
        setSkills(mapper.convertSkillsToSet(data));
      });
    }
  }, [workRecord]);

  useEffect(() => {
    if (wrForceUpdate) {
      WorkRecordClient.get(cParams.wrId).then((data: any) => {
        const wr: WorkRecordInterface = WorkRecordMapper.creatWorkRecordFromStaffResponse(data);
        store.dispatch(setWorkRecord(wr));
        store.dispatch(setShouldUpdate(false));
      });
    }
  }, [wrForceUpdate]);

  if (workRecord) {
    return (
      <div className="work-record-main">
        <div className="work-record-main__header">
          <h1 className="work-record-main__title">Work Record</h1>
          { workRecord?.code && (<p className="work-record-main__sub-title">Id {workRecord.code}</p>) }
        </div>
        <div className="work-record-main__body">
          <div className="row">
            <div className="col-8">
              <div className="card">
                <div className="card-body">
                  <p className="card-title">
                    Position Description
                    <ElementWithPermission element="editPosition" staffToCheck={{}}>
                      <PopupTrigger popupName="positionDescription">
                        <span className="popup-button">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-0.000732422 9.64069V11.6674C-0.000732422 11.854 0.145934 12.0007 0.332601 12.0007H2.35927C2.44593 12.0007 2.5326 11.9674 2.5926 11.9007L9.8726 4.62736L7.3726 2.12736L0.0992676 9.40069C0.032601 9.46736 -0.000732422 9.54736 -0.000732422 9.64069ZM11.8059 2.69402C12.0659 2.43402 12.0659 2.01402 11.8059 1.75402L10.2459 0.194023C9.98593 -0.0659766 9.56593 -0.0659766 9.30593 0.194023L8.08593 1.41402L10.5859 3.91402L11.8059 2.69402Z" fill="#0A88EC"/>
                          </svg>Edit
                        </span>
                      </PopupTrigger>
                      <Popup popupName="positionDescription" id="popupDescription" title="Position Description" size="lg">
                        <PositionDescriptionUpdateWrapper workRecord={ workRecord } staff={ staff } popupName={ 'positionDescription' } />
                      </Popup>
                    </ElementWithPermission>
                  </p>
                  <WRAttributes mode={ MODE_VIEW } />
                </div>
              </div>
            </div>
            <div className="col-4">
              { workRecord && <WRSkill type="hardSkills" title="Hard Skills" skills={ skills } workRecord={ workRecord }/> }
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4">
              { workRecord && <WRSkill type="softSkills" title="Soft Skills" skills={ skills } workRecord={ workRecord } /> }
            </div>
            <div className="col-4">
              { workRecord && <WRSkill type="languageSkills" title="Languages" skills={ skills } workRecord={ workRecord } /> }
            </div>
          </div>
        </div>
        <div className="work-record-main__footer">
          {
            (workRecord.status === STATUSES.OFFER_ACCEPTED)
              ? (
                <ElementWithPermission element="activateWorkRecord" staffToCheck={{}}>
                  <div className="activate-wr-button">
                    <PopupTrigger popupName="activateWorkRecord">
                      <span className="popup-button">Activate Work Record</span>
                    </PopupTrigger>
                  </div>
                </ElementWithPermission>
              )
              : <></>
          }
          {
            (workRecord.status === STATUSES.ACTIVE)
              ? (
                <ElementWithPermission element="closeWorkRecord" staffToCheck={{}}>
                  <div className="close-wr-button">
                    <PopupTrigger popupName="closeWorkRecord">
                      <span className="popup-button">Close Work Record</span>
                    </PopupTrigger>
                  </div>
                </ElementWithPermission>
              )
              : <></>
          }
          <Popup popupName="activateWorkRecord" id="activateWorkRecord" title={'\u00A0'} size="lg">
            <ActivateWorkRecordForm popupName={'activateWorkRecord'} workRecord={workRecord} />
          </Popup>
          <Popup popupName="closeWorkRecord" id="closeWorkRecord" title={'\u00A0'} size="lg">
            <CloseWorkRecordForm popupName={'closeWorkRecord'} workRecord={workRecord}/>
          </Popup>
        </div>
      </div>
    );
  }

  return <></>;
}
