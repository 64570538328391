import React from 'react'

import { Skill } from '../../../interfaces/entity/Skill'
import { PopoverAttributeFuture } from '../../Popovers/PopoverAttributeFuture/PopoverAttributeFuture'

import './work-record-skill-tree.scss'

interface WRSkillTreeProps {
  skills: Array<Skill>;
  type: 'hardSkills' | 'softSkills' | 'languageSkills';
}

export const WRSkillTree: React.FC<WRSkillTreeProps> = (props: WRSkillTreeProps) => {
  const { skills } = { ...props }
  if (!skills) return <></>
  return (
    <div className="work-record-skills">
      {
        skills.map((skill: Skill) => (
          <div key={ skill.id } className="position-relative">
            <div
              className={`work-record-skill work-record-skill--level-${skill.hierarchyLevel} clearfix`}>
              <div className="work-record-skill__name">{ skill.originalName }</div>
              <div className="work-record-skill__level">{ skill.level }</div>
            </div>
            {
              skill.future.length
                ? (
                  <div className="position-absolute top-right" style={ { top: 0, right: '-20px' }}>
                    <PopoverAttributeFuture future={ skill.future } placement={ props.type === 'hardSkills' ? 'left' : 'right' } />
                  </div>
                )
                : <></>
            }
          </div>
        ))
      }
    </div>
  )
}
