import { SkillLevelT } from '../interfaces/entity/Skill'

export const STATUSES = {
  'ACTIVE': 'Active',
  'INACTIVE': 'Inactive',
  'OFFER_ACCEPTED': 'Offer Accepted',
  'DELETED': 'Deleted'
}

export const ROLES = {
  'HR': 'ROLE_HR',
  'ADMIN': 'ROLE_ADMIN',
  'LM': 'ROLE_LM',
  'OWNER': 'ROLE_OWNER',
  'PP': 'ROLE_PP',
  'HR_REC': 'ROLE_HR_REC'
}

export const TRIAL_STATUSES = {
  'NOT_SET': {
    name: 'No trial',
    id: 0
  },
  'IN_PROGRESS': {
    name: 'In progress',
    id: 1
  },
  'PASSED': {
    name: 'Passed',
    id: 2
  },
  'CANCELLED': {
    name: 'Cancelled',
    id: 3
  },
  'FAILED': {
    name: 'Failed',
    id: 4
  },
  'PENDING': {
    name: 'Pending',
    id: 5
  }
}

export const TRIAL_STATUSES_CLOSE = {
  'NOT_SET': {
    name: 'No trial',
    id: 0
  },
  'IN_PROGRESS': {
    name: 'In progress',
    id: 1
  },
  'PASSED': {
    name: 'Passed',
    id: 2
  },
  'FAILED': {
    name: 'Failed',
    id: 4
  }
}

export const SKILL_RESPONSE_MAP = {
  hardSkills: 'Hard Skills',
  softSkills: 'Soft Skills',
  languageSkills: 'Languages',
}

export const SKILL_LEVEL = {
  hardSoft: ['Beginner', 'Intermediate', 'Advanced'],
  language: ['Beginner', 'Intermediate', 'Advanced', 'Native']
}

export const TABLE_NAMES = {
  ALL: 'all',
  SUBORDINATES: 'subordinates',
  DIRECT: 'direct'
}

export const MY_TEAM_TYPES = {
  SUBORDINATE: 'subordinate',
  CSV_DATA_FILE_PREFIX_SUBORDINATE: 'Subordinates',
  DIRECT_TEAM: 'direct',
  CSV_DATA_FILE_PREFIX_DIRECT_TEAM: 'Direct',
  SUBORDINATE_LABEL: 'Subordinates',
  DIRECT_TEAM_LABEL: 'Direct Reports'
}

export const MY_TEAM_TABS = {
  TEAM_INFO: 'teamInfo',
  LEAVES: 'leaves',
  LEAVES_LINK: 'https://rms.star.global/vacation?view_type=all#sort=&amp;view_type=approve',
  LEAVES_LINK_NAME: 'My Team Leaves'
}

export const MY_TEAM_TABS_INFO = [
  {label: 'TEAM INFO', key: MY_TEAM_TABS.TEAM_INFO, isActive: true},
  {label: 'LEAVES', key: MY_TEAM_TABS.LEAVES, isActive: false}
]
