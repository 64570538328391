import moment from 'moment';
import {WorkRecordStructure} from '../../interfaces/http/staff/WorkRecordStructure';
import {WorkRecord as WorkRecordInterface} from '../../interfaces/WorkRecord';
import {AttributeStructure} from '../../interfaces/http/common/AttributeStructure';

import WorkRecord from '../../models/WorkRecord';

import attributeMapper from './AttributeMapper';

import { ATTRIBUTES_MAP, STATUSES, TRIAL_STATUSES } from '../../constants';

class WorkRecordMapper {
  public creatWorkRecordFromStaffResponse(data: WorkRecordStructure, staffId?: number): WorkRecordInterface {
    let workRecord: WorkRecordInterface = new WorkRecord();
    workRecord.id = data.id;
    workRecord.staffId = (staffId) ? staffId : data.staff.id;
    workRecord.status = data.status;
    workRecord.code = data.code;
    workRecord.startDate = moment(data.start_date).toDate();
    workRecord.endDate = (data.end_date) ? moment(data.end_date).toDate() : null;
    workRecord.trialStatus = data.trial_status;
    workRecord.trialEndDate = (data.trial_end_date) ? moment(data.trial_end_date).toDate() : null;
    workRecord.tenure = (data.tenure) ? data.tenure : NaN;

    workRecord.isTrial = [TRIAL_STATUSES.NOT_SET.name, TRIAL_STATUSES.CANCELLED.name].indexOf(data.trial_status) === -1;
    workRecord.isInactive = workRecord.status === STATUSES.INACTIVE;

    data.attributes_list?.forEach((attribute: any, i, arr) => {
      const alias = attribute.attribute;
      const name: string = ATTRIBUTES_MAP.workRecord[alias];
      const current = attribute.current[0];
      const future = attribute.future;
      if (current && current.attribute !== 'skill' && current.value !== undefined) {
        const attributeFormatted = attributeMapper.creatWRAttributeFromStaffResponse(current);
        workRecord.attributes.set(name, attributeFormatted);
        // @ts-ignore
        workRecord[name] = attributeFormatted.value;
      }
      attributeMapper.addPreviousValue(attribute.future, current && current.value);
      workRecord.attributesFuture.set(name, future);
    });

    // data.attributes.map((attr: AttributeStructure) => {
    //   const alias: string = attr.attribute;
    //   const name: string = ATTRIBUTES_MAP.workRecord[alias];
    //   const attribute = attributeMapper.creatWRAttributeFromStaffResponse(attr);
    //   workRecord.attributes.set(name, attribute);
    //   // @ts-ignore
    //   workRecord[name] = attribute.value;
    //   return attribute;
    // });

    return workRecord;
  }
}

export default new WorkRecordMapper();
