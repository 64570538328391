import React, {Fragment, FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FontAwesomeIcon as FA} from '@fortawesome/react-fontawesome';

import {GlobalState} from '../../../store/types';
import {WORK_RECORD_STATE} from '../../../store/reducers';

import dictionaryStore from '../../../services/dictionary/DictionaryStore';
import {ATTRIBUTE_NAMES, TRIAL_STATUSES} from '../../../constants';

import {Popup} from '../../Popup/Popup';
import {PopupTrigger} from '../../Popup/PopupTrigger';
import {TurnoverForm} from '../Forms/Turnover/TurnoverForm';
import {ReasonToLeaveForm} from '../Forms/ReasonToLeave/ReasonToLeaveForm';
import {NextEmployerForm} from '../Forms/NextEmployer/NextEmployerForm';
import {AttributeWithPermission} from '../Common/Attribute/AttributeWithPermission';
import {PopoverLineManager} from '../../Popovers/PopoverLineManager/PopoverLineManager';
import {PopoverAttributeFuture} from '../../Popovers/PopoverAttributeFuture/PopoverAttributeFuture';
import {TrialStatusForm} from '../Forms/TrialStatus/TrialStatusForm';
import {
  WRAttributeBoolean,
  WRAttributeBreezyLink ,
  WRAttributeDate,
  WRAttributeIconed,
  WRAttributeJiraLink,
  WRAttributeLocation,
  WRAttributePercent,
  WRAttributePlain,
  WRAttributeStaff,
  WRAttributeString
} from '../WRAttribute';

const labels = ATTRIBUTE_NAMES['wrMain'];
export const MODE_VIEW = 'view';
export const MODE_PREVIEW = 'preview';

type PropsType = {
  mode: typeof MODE_VIEW | typeof MODE_PREVIEW;
}

export const WRAttributes: FC<PropsType> = (props: PropsType) => {
  const { mode } = { ...props };
  const workRecord = useSelector((state: GlobalState) => state[WORK_RECORD_STATE].workRecord);

  const [dictionary, setDictionary] = useState<any>({});
  const [isDictLoading, setIsDictLoading] = useState(false);

  useEffect(() => {
    if (!isDictLoading) {
      setIsDictLoading(true);
      dictionaryStore.getAll().then((data: any) => {
        if (typeof data !== 'undefined') {
          setDictionary(data);
        }
      });
    }
  }, [isDictLoading]);
  
  const canEditTrialStatus = mode === MODE_VIEW && [
    TRIAL_STATUSES.IN_PROGRESS.name,
    TRIAL_STATUSES.PASSED.name,
    TRIAL_STATUSES.FAILED.name
  ].indexOf(workRecord.trialStatus) !== -1;

  if (workRecord) {
    return (
      <Fragment>
        <div className="row mb-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='position'
              attribute={
                <WRAttributeString
                  name={labels['position']}
                  value={workRecord.position}
                  tooltip={
                    (workRecord.attributesFuture.get('position'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('position')}/>
                      : <></>
                  }
                />
              }
            />
          </div>
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='seniority'
              attribute={
                <WRAttributeString
                  name={labels['seniority']}
                  value={workRecord.seniority}
                  tooltip={
                    (workRecord.attributesFuture.get('seniority'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('seniority')}/>
                      : <></>
                  }
                />
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='position'
              attribute={<WRAttributeString name={labels['dates']} value={workRecord.getDates()}/>}
            />
          </div>
          <div className="col" />
        </div>
        <div className="row mb-4">
          <div className="col">
            <AttributeWithPermission
              wr={ workRecord }
              name='grLevel'
              attribute={
                <WRAttributeString
                  name={ labels['grLevel'] }
                  value={ workRecord.grLevel }
                  tooltip={
                    (workRecord.attributesFuture.get('grLevel'))
                      ? <PopoverAttributeFuture future={ workRecord.attributesFuture.get('grLevel') } />
                      : <></>
                  }
                />
              }
            />
          </div>
          <div className="col">
            <AttributeWithPermission
              wr={ workRecord }
              name='cohort'
              attribute={
                <WRAttributeString
                  name={ labels['cohort'] }
                  value={ workRecord.cohort }
                  tooltip={
                    (workRecord.attributesFuture.get('cohort'))
                      ? <PopoverAttributeFuture future={ workRecord.attributesFuture.get('cohort') } />
                      : <></>
                  }
                />
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='isBillable'
              attribute={
                <WRAttributeBoolean
                  name={labels['isBillable']}
                  value={workRecord.isBillable}
                  tooltip={
                    (workRecord.attributesFuture.get('isBillable'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('isBillable')}/>
                      : <></>
                  }
                />
              }
            />
          </div>
          <div className="col">
            {
              workRecord.isBillable?.value && (
                <AttributeWithPermission
                  wr={workRecord}
                  name='billingLevel'
                  attribute={
                    <WRAttributeString
                      name={labels['billingLevel']}
                      value={workRecord.billingLevel}
                      tooltip={
                        (workRecord.attributesFuture.get('billingLevel'))
                          ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('billingLevel')}/>
                          : <></>
                      }
                    />
                  }
                />
              )
            }
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='maxTotalLoad'
              attribute={
                <WRAttributePercent
                  name={labels['maxTotalLoad']}
                  value={workRecord.maxTotalLoad}
                  tooltip={
                    (workRecord.attributesFuture.get('maxTotalLoad'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('maxTotalLoad')}/>
                      : <></>
                  }
                />
              }
            />
          </div>
          <div className="col">
            {
              workRecord.isBillable.value &&
              <AttributeWithPermission
                wr={workRecord}
                name='maxBillableLoad'
                attribute={
                  <WRAttributePercent
                    name={labels['maxBillableLoad']}
                    value={workRecord.maxBillableLoad}
                    tooltip={
                      (workRecord.attributesFuture.get('maxBillableLoad'))
                        ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('maxBillableLoad')}/>
                        : <></>
                    }
                  />
                }
              />
            }
          </div>
        </div>
        <hr className="my-0"/>
        <div className="row my-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='location'
              attribute={
                <WRAttributeLocation
                  name={labels['location']}
                  value={workRecord.location}
                  tooltip={
                    (workRecord.attributesFuture.get('location'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('location')}/>
                      : <></>
                  }
                  isLink={true}
                />
              }
            />
          </div>
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='costLocation'
              attribute={
                <WRAttributeLocation
                  name={labels['costLocation']}
                  value={workRecord.costLocation}
                  tooltip={
                    (workRecord.attributesFuture.get('costLocation'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('costLocation')}/>
                      : <></>
                  }
                  isLink={false}
                />
              }
            />
          </div>
        </div>
        <div className="row my-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='costCenter'
              attribute={
                <WRAttributeString
                  name={ labels['costCenter'] }
                  value={ workRecord.costCenter }
                  tooltip={
                    (workRecord.attributesFuture.get('costCenter'))
                      ? <PopoverAttributeFuture future={ workRecord.attributesFuture.get('costCenter') } />
                      : <></>
                  }
                />
              }
            />
          </div>
          <div className="col"></div>
        </div>
        <hr className="my-0"/>
        <div className="row my-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='unit'
              attribute={
                <WRAttributePlain
                  name={labels['orgStructure']}
                  value={workRecord.unit.orgStructure}
                  tooltip={
                    (workRecord.attributesFuture.get('unit'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('unit')} alias='orgStructure'/>
                      : <></>
                  }
                />
              }
            />
          </div>
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='unit'
              attribute={
                <WRAttributeStaff
                  name={labels['orgStructureManager']}
                  value={workRecord.unit.orgStructureManager}
                  tooltip={
                    (workRecord.attributesFuture.get('unit'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('unit')} alias='orgStructureManager'/>
                      : <></>
                  }
                />
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='unit'
              attribute={
                <WRAttributePlain
                  name={labels['department']}
                  value={workRecord.unit.department}
                  tooltip={
                    (workRecord.attributesFuture.get('unit'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('unit')} alias='department'/>
                      : <></>
                  }
                />
              }
            />
          </div>
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='unit'
              attribute={
                <WRAttributeStaff
                  name={labels['departmentManager']}
                  value={workRecord.unit.departmentManager}
                  tooltip={
                    (workRecord.attributesFuture.get('unit'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('unit')} alias='departmentManager'/>
                      : <></>
                  }
                />
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='unit'
              attribute={
                <WRAttributePlain
                  name={labels['subDepartment']}
                  value={workRecord.unit.subDepartment}
                  tooltip={
                    (workRecord.attributesFuture.get('unit'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('unit')} alias='subDepartment'/>
                      : <></>
                  }
                />
              }
            />
          </div>
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='unit'
              attribute={
                <WRAttributeStaff
                  name={ labels['subDepartmentManager'] }
                  value={ workRecord.unit.subDepartmentManager }
                />
              }
            />
          </div>
        </div>
        <hr className="my-0"/>
        <div className="row my-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='mentor'
              attribute={
                <WRAttributeStaff
                  name={labels['mentor']}
                  value={workRecord.mentor}
                  tooltip={
                    (workRecord.attributesFuture.get('mentor'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('mentor')}/>
                      : <></>
                  }
                />
              }
            />
          </div>
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='lineManager'
              attribute={
                <WRAttributeStaff
                  name={labels['lineManager']}
                  value={workRecord.lineManager}
                  tooltip={
                    <>
                      <PopoverLineManager value={workRecord.lineManager} staffId={workRecord.staffId} wrId={workRecord.id}/>
                      {
                        (workRecord.attributesFuture.get('lineManager'))
                          ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('lineManager')}/>
                          : <></>
                      }
                    </>
                  }
                />
              }
            />
          </div>
        </div>
        <hr className="my-0"/>
        <div className="row my-4">
          <div className="col">
            {
              (workRecord.jiraRecId.value && !workRecord.recruitmentId.value) ? (
                <AttributeWithPermission
                  wr={workRecord}
                  name='jiraRecId'
                  attribute={
                    <WRAttributeJiraLink
                      name={labels['jiraRecId']}
                      value={workRecord.jiraRecId}
                      tooltip={
                        (workRecord.attributesFuture.get('jiraRecId'))
                          ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('jiraRecId')}/>
                          : <></>
                      }
                    />
                  }
                />
              ) : (
                <AttributeWithPermission
                  wr={workRecord}
                  name='recruitmentId'
                  attribute={
                    <WRAttributeBreezyLink
                      name={labels['recruitmentId']}
                      value={workRecord.recruitmentId}
                      tooltip={
                        (workRecord.attributesFuture.get('recruitmentId'))
                          ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('recruitmentId')}/>
                          : <></>
                      }
                    />
                  }
                />
              )
            }
          </div>
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='peoplePartner'
              attribute={
                <WRAttributeStaff
                  name={labels['peoplePartner']}
                  value={workRecord.peoplePartner}
                  tooltip={
                    (workRecord.attributesFuture.get('peoplePartner'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('peoplePartner')}/>
                      : <></>
                  }
                />
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='trialStatus'
              attribute={
                <WRAttributePlain
                  name={ labels['trialStatus'] }
                  value={ String(workRecord.trialStatus) }
                  afterValueChild={
                    <AttributeWithPermission
                      wr={ workRecord }
                      name='trialStatus'
                      permission='edit'
                      attribute={
                        canEditTrialStatus
                          ? (
                            <>
                              <PopupTrigger popupName="trialStatus">
                                <span className="popup-button"> <FA icon={['far', 'edit']}/></span>
                              </PopupTrigger>
                              <Popup
                                popupName="trialStatus"
                                id="trialStatus"
                                title="Set Trial Status"
                                size="sm"
                                centered={ true }
                              >
                                <TrialStatusForm workRecord={ workRecord } />
                              </Popup>
                            </>
                          )
                          : <></>
                      }
                    />
                  }
                />
              }
            />
          </div>
          <div className="col">
            <WRAttributeDate name={labels['trialEndDate']} value={workRecord.trialEndDate}/>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <AttributeWithPermission
              wr={workRecord}
              name='employment'
              attribute={
                <WRAttributeString
                  name={labels['employment']}
                  value={workRecord.employment}
                  tooltip={
                    (workRecord.attributesFuture.get('employment'))
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('employment')}/>
                      : <></>
                  }
                />
              }
            />
          </div>
        </div>
        {
          (workRecord.isInactive) ?
            (
              <div className="row mb-4">
                <div className="col">
                  <AttributeWithPermission
                    wr={workRecord}
                    name='turnover'
                    attribute={
                      <WRAttributeString
                        name={labels['turnover']}
                        value={workRecord.turnover}
                        afterValueChild={
                          <AttributeWithPermission
                            wr={workRecord}
                            name='turnover'
                            permission='edit'
                            attribute={
                              <Fragment>
                                <PopupTrigger popupName="turnover">
                                  <span className="popup-button"> <FA icon={['far', 'edit']}/></span>
                                </PopupTrigger>
                                <Popup
                                  popupName="turnover"
                                  id="turnover"
                                  title="Edit Turnover"
                                  size="sm"
                                  centered={ true }>
                                  <TurnoverForm workRecord={ workRecord } />
                                </Popup>
                              </Fragment>
                            }
                          />
                        }
                      />
                    }
                  />
                </div>
                <div className="col">
                  <AttributeWithPermission
                    wr={ workRecord }
                    name='reasonToLeave'
                    attribute={
                      <WRAttributeString
                        name={ labels['reasonToLeave'] }
                        value={ (workRecord.reasonToLeave) }
                        afterValueChild={
                          <AttributeWithPermission
                            wr={ workRecord }
                            name='reasonToLeave'
                            permission='edit'
                            attribute={
                              <Fragment>
                                <PopupTrigger popupName="reasonToLeave">
                                  <span className="popup-button"> <FA icon={['far', 'edit']}/></span>
                                </PopupTrigger>
                                <Popup
                                  popupName="reasonToLeave"
                                  id="reasonToLeave"
                                  title="Edit Reason To Leave"
                                  size="sm"
                                  centered={ true }>
                                  <ReasonToLeaveForm workRecord={ workRecord } />
                                </Popup>
                              </Fragment>
                            }
                          />
                        }
                      />
                    }
                  />
                </div>
              </div>
            ) : ''
        }
        <div className="row mb-4">
          {
            (workRecord.tenure && !isNaN(workRecord.tenure))
              ? (
                <div className="col">
                  <WRAttributePlain
                    name={ labels['tenure'] }
                    value={(workRecord.tenure) ? String(workRecord.tenure) : ''}/>
                </div>
              )
              : <></>
          }
          {
            (workRecord.isInactive) ?
              (
                <div className="col-6">
                  <AttributeWithPermission
                    wr={workRecord}
                    name='nextEmployer'
                    attribute={
                      <WRAttributeString
                        name={labels['nextEmployer']}
                        value={(workRecord.nextEmployer)}
                        afterValueChild={
                          <Fragment>
                            <AttributeWithPermission
                              wr={workRecord}
                              name='nextEmployer'
                              permission='edit'
                              attribute={
                                <Fragment>
                                  <PopupTrigger popupName="nextEmployer">
                                    <span className="popup-button"> <FA icon={['far', 'edit']}/></span>
                                  </PopupTrigger>
                                  <Popup
                                    popupName="nextEmployer"
                                    id="nextEmployer"
                                    title="Edit Next Employer"
                                    size="sm"
                                    centered={true}>
                                    <NextEmployerForm workRecord={workRecord}/>
                                  </Popup>
                                </Fragment>
                              }
                            />
                          </Fragment>}
                      />
                    }
                  />
                </div>
              ) : ''
          }
        </div>
      </Fragment>
    );
  }

  return <Fragment/>;
}
