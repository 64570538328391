import * as Yup from 'yup';
import { VALIDATION_NAMES } from '../../../../constants';

const dictionaryTransform = (currentV: any, originalV: any) => (originalV?.id && !isNaN(originalV.id)) ? originalV.id : null;

export const schema = [
  Yup.object().shape({
    username: Yup.string()
      .nullable(),
    photo: Yup.string()
      .nullable(),
    firstName: Yup.string()
      .required('First Name is required')
      .matches(VALIDATION_NAMES),
    lastName: Yup.string()
      .required('Last Name is required')
      .matches(VALIDATION_NAMES),
    middleName: Yup.string()
      .matches(VALIDATION_NAMES)
      .nullable(),
    dateOfBirth: Yup.date()
      .nullable(),
    gender: Yup.string()
      .nullable(),
    email: Yup.string()
      .email("Email has to be valid")
      .nullable(),
    skype: Yup.string()
      .nullable(),
    phone1: Yup.string()
      .nullable(),
    phone2: Yup.string()
      .nullable(),
    emergContactName: Yup.string()
      .nullable(),
    emergContactPhone: Yup.string()
      .nullable(),
    facebook: Yup.string()
      .nullable(),
    linkedIn: Yup.string()
      .nullable(),
    wiki: Yup.string()
      .nullable(),
    companyStartDate: Yup.date()
      .nullable(),
    alumni: Yup.boolean(),
    country: Yup
      .string()
      .nullable(),
    city: Yup
      .string()
      .nullable()
      .test(
        'required',
        'City cannot be defined without Country',
        function (val: any) {
          if (!this.parent.country && val) {
            return this.createError({
              message: 'City cannot be defined without Country',
              path: 'city',
            });
          }
          return true
        }
      )
  }),
  Yup.object().shape({
    employment: Yup.number()
      .required('Employment is required')
      .transform(dictionaryTransform),
    startDate: Yup.date()
      .typeError('Start Date must be set')
      .required('Start Date is required'),
    endDate: Yup.date()
      .nullable()
      .notRequired()
      .test('min', 'Trial End Date can\'t be less than Start Date', function (value) {
        const { startDate } = this.parent;
        if (startDate && value && value < startDate) {
          return this.createError({
            message: 'End Date can\'t be less than Start Date',
            path: 'endDate',
          });
        }
        return true;
      }),
    trialPeriod: Yup.boolean(),
    trialEndDate: Yup.date()
      .when('trialPeriod', {
        is: true,
        then: (schema) => schema.required('Trial End Date is required'),
        otherwise: (schema) => schema.nullable()
      })
      .test('min', 'Trial End Date can\'t be less than Start Date', function (value) {
        const { startDate } = this.parent;
        if (startDate && value && value < startDate) {
          return this.createError({
            message: 'Trial End Date can\'t be less than Start Date',
            path: 'trialEndDate',
          });
        }
        return true;
      })
      .test('max', 'Trial End Date can\'t be greater than End Date', function (value) {
        const { endDate } = this.parent;
        if (endDate && value && value > endDate) {
          return this.createError({
            message: 'Trial End Date can\'t be greater than End Date',
            path: 'trialEndDate',
          });
        }
        return true;
      }),
    position: Yup.number()
      .typeError('Position must be set')
      .required('Position is required')
      .transform(dictionaryTransform),
    seniority: Yup.string()
      .nullable()
      .transform(dictionaryTransform),
    unit: Yup.number()
      .typeError('Org. Unit must be set')
      .required('Org. Unit is required')
      .transform(dictionaryTransform),
    location: Yup.number()
      .typeError('Location must be set')
      .required('Location is required')
      .transform(dictionaryTransform),
    costLocation: Yup.number()
      .required('Cost Location is required')
      .transform(dictionaryTransform),
    isBillable: Yup.boolean(),
    billingLevel: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    lineManager: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    mentor: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    jiraRecId: Yup.string(),
    recruitmentId: Yup.string(),
    peoplePartner: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    grLevel: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    cohort: Yup.number()
      .nullable()
      .transform(dictionaryTransform)
      .test(
        'required',
        'Cohort cannot be defined without Consultancy Ranking',
        function (val: any) {
          if (!this.parent.grLevel && val) {
            return this.createError({
              message: 'Cohort cannot be defined without Consultancy Ranking',
              path: 'cohort'
            })
          }
          return true
        }
      ),
    maxTotalLoad: Yup.number()
      .min(0)
      .max(100),
    maxBillableLoad: Yup.number()
      .nullable(),
    costCenter: Yup.number()
      .required('Cost Center is required')
      .transform(dictionaryTransform),
  })
]
