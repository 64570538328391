import React from 'react'

export const useFixedTableHeader = (ref: any) => {
  React.useEffect(() => {
    if (!ref) return
    
    function handleScroll(event: any){
      const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
      const styles = event.currentTarget.scrollTop > 0
        ? { position: isSafari ? '-webkit-sticky': 'sticky', top: 0, zIndex: 5 }
        : { position: 'static', top: 'auto', zIndex: 'auto' }
      const headers = event.currentTarget.querySelectorAll('[data-header="fixed"]')
      if (headers?.length) [...headers].forEach((header: any) => {
        header.style.position = styles.position
        header.style.top = styles.top
        header.style.zIndex = styles.zIndex
      })
    }
    
    const element = ref.current as unknown as HTMLElement
    element.addEventListener('scroll', handleScroll)
    return () => {
      element.removeEventListener('scroll', handleScroll);
    }
  }, [ref])
}
