import moment from 'moment'

import {ATTRIBUTES_MAP} from '../../constants';
import {AttributeStructure} from '../../interfaces/http/common/AttributeStructure';
import {Attribute as AttributeInterface} from '../../interfaces/Attribute';
import Attribute from '../../models/Attribute';
import ValueString from '../../models/ValueString';
import ValueBoolean from '../../models/ValueBoolean';
import ValueNumber from '../../models/ValueNumber';
import ValueDate from '../../models/ValueDate';
import ValueNull from '../../models/ValueNull';
import ValueDictionary from '../../models/ValueDictionary';
import ValueStaff from '../../models/ValueStaff';
import ValueLocation from '../../models/ValueLocation';
import ValueUnit from '../../models/ValueUnit';
import ValuePhysicalLocation from "../../models/ValuePhysicalLocation";

//TODO refactor
class AttributeMapper {
  public creatStaffAttributeFromStaffResponse(attr: AttributeStructure): AttributeInterface {
    const alias: string = attr.attribute;
    const name: string = ATTRIBUTES_MAP.staff[alias];
    const cValue = attr.value;
    let attribute = new Attribute();
    attribute.alias = alias;
    attribute.name = name;
    attribute.future = attr.future?.length ? this.addPreviousValue(attr.future, cValue) : [];
    let value;
    switch (alias) {
      case 'email':
      case 'skype':
      case 'phone1':
      case 'phone2':
      case 'facebook':
      case 'linkedin':
      case 'wiki':
      case 'aboutme':
      case 'first_name':
      case 'last_name':
      case 'middle_name':
      case 'gender':
      case 'emerg_contact_name':
      case 'emerg_contact_phone':
        value = new ValueString(cValue);
        break;
      case 'date_of_birth':
        value = (cValue) ? new ValueDate(moment(cValue).toDate()) : new ValueNull()
        break;
      case 'physical_location':
        value = new ValuePhysicalLocation();
        value.id = cValue.id;
        value.countryName = cValue.country_name;
        value.cityName = cValue.city_name;
        value.region = cValue.region;
        value.timeZone = cValue.time_zone
        break;
      default:
        value = new ValueNull();
    }
    value.valueId = attr.id;
    attribute.value = value;

    return attribute;
  }

  public creatWRAttributeFromStaffResponse(attr: AttributeStructure): AttributeInterface {
    const alias: string = attr.attribute;
    const name: string = ATTRIBUTES_MAP.workRecord[alias];
    const cValue = attr.value;
    let attribute = new Attribute();
    attribute.alias = alias;
    attribute.name = name;
    let value;
    switch (alias) {
      case 'seniority':
      case 'jira_rec_id':
      case 'recruitment_id':
      case 'turnover':
      case 'next_employer':
        value = new ValueString(cValue);
        break;
      case 'is_billable':
        value = new ValueBoolean();
        value.value = (cValue === '1');
        break;
      case 'max_total_load':
      case 'max_billable_load':
        value = new ValueNumber();
        value.value = Number(cValue);
        break;
      case 'employment':
      case 'position':
      case 'billing_level':
      case 'cost_center':
      case 'cohort':
        value = new ValueDictionary()
        value.value = cValue?.name
        value.id = cValue?.id
        value.description = (cValue?.description) ? cValue.description : ''
        break;
      case 'gr_level':
        value = new ValueDictionary()
        value.value = cValue.title
        value.id = cValue.id
        break;
      case 'reason_to_leave':
        value = new ValueDictionary();
        value.value = cValue.title;
        value.id = cValue.id;
        value.description = (cValue.description) ? cValue.description : '';
        break;
      case 'line_manager':
      case 'mentor':
      case 'people_partner':
        value = new ValueStaff();
        value.value = cValue.name;
        value.staffId = cValue.id;
        value.staffFullName = cValue.full_name;
        value.firstName = cValue.first_name ? cValue.first_name : '';
        value.lastName = cValue.last_name ? cValue.last_name : '';
        break;
      case 'location':
      case 'cost_location':
        value = new ValueLocation();
        value.value = cValue.name;
        value.id = cValue.id;
        value.country = cValue.country.name;
        break;
      case 'unit':
        value = new ValueUnit();
        value.value = (cValue.full_name) ? cValue.full_name : cValue.name;
        value.id = cValue.id;
        value.level = Number(cValue.level);
        //Set OS
        const orgStructure = cValue.org_structure
        if (orgStructure) {
          value.orgStructure = orgStructure.name;
          if (orgStructure.head) {
            value.orgStructureManager = this.createManagerFromHead(orgStructure.head);
          }
        }
        if (value.level === 1) {
          value.department = (cValue.department_full_name) ? cValue.department_full_name : cValue.name;
          if (cValue.head) {
            value.departmentManager = this.createManagerFromHead(cValue.head);
          }
        }
        if (value.level === 2) {
          value.subDepartment = (cValue.sub_department_full_name) ? cValue.sub_department_full_name : cValue.name;
          value.department = (cValue.department_full_name) ? cValue.department_full_name : cValue.name;
          if (cValue.department_manager) {
            value.departmentManager = this.createManagerFromHead(cValue.department_manager);
          }
          if (cValue.head) {
            value.subDepartmentManager = this.createManagerFromHead(cValue.head);
          }
        }
        break;
      default:
        value = new ValueNull();
    }
    value.valueId = attr.id;
    attribute.value = value;
    return attribute;
  }

  public addPreviousValue(futureList: Array<any>, current: any) {
    futureList.forEach((future: any, key: number) => {
      if (key === 0) {
        future.previousValue = current;
      } else {
        future.previousValue = futureList[key - 1].value;
      }
    });
    return futureList;
  }

  private createManagerFromHead(head:any): ValueStaff {
    let manager = new ValueStaff();
    manager.value = head.full_name;
    manager.staffId = head.id;
    manager.firstName = head.first_name;
    manager.lastName = head.last_name;
    manager.staffFullName = head.full_name;
    return manager;
  }
}

export default new AttributeMapper();
