import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { Typeahead } from 'react-bootstrap-typeahead'
import Select from 'react-select'

import { Skill as SkillInterface, SkillLevelT } from '../../interfaces/entity/Skill'
import Skill from '../../models/entity/Skill'
import ArrayHelper from '../../utils/ArrayHelper'
import { SKILL_LEVEL, ATTRIBUTE_NAMES } from '../../constants'

import './skill-field.scss'

const labels = ATTRIBUTE_NAMES['wrSkills'];

interface SkillFieldProps {
  id: string;
  name: string;
  labelKey: string;
  options: Array<any>;
  isLanguage: boolean;
}

export const SkillField: React.FC<SkillFieldProps> = (props: SkillFieldProps) => {
  const {id, labelKey, options, isLanguage} = {...props};
  const levels = (isLanguage) ? SKILL_LEVEL.language : SKILL_LEVEL.hardSoft;
  const [field, , { setValue }] = useField<any>(props as any);
  const [currentOptions, setCurrentOptions] = useState<Array<any>>([]);
  const [values, setValues] = useState<Array<SkillInterface>>([]);
  const [taRef, setTARef] = useState<any>(null);

  const addSkill = (value: SkillInterface) => {
    taRef.clear();

    const skill: SkillInterface = new Skill();
    skill.id = value.id;
    skill.name = value.name;
    skill.originalName = value.originalName;
    setValues(ArrayHelper.pushToNew(values, skill));
    setCurrentOptions(ArrayHelper.removeInNewByField(currentOptions, skill, 'id'));
  }

  const modifySkill = (level: SkillLevelT, skill: Skill) => {
    skill.level = level;
    setValue(ArrayHelper.replaceInArray(values, skill, 'id'));
  }

  const removeSkill = (skill: SkillInterface) => {
    setValues(ArrayHelper.removeInNewByField(values, skill, 'id'));
    setCurrentOptions(ArrayHelper.sortByField(ArrayHelper.pushToNew(currentOptions, skill), 'name'));
  }

  useEffect(() => {
    if (!currentOptions.length) {
      setCurrentOptions(options)
    }
    setValue(values)
  }, [options, values])
  
  return (
    <>
      <div className="form-row" id={id}>
        {
          values.map((skill: SkillInterface) => (
            <React.Fragment key={ skill.id }>
              <div className="form-group col-6">
                <label className="skills-form--label">Skill or subject area</label>
                <div className="form-control">{ skill.originalName }</div>
              </div>
              <div className="form-group col-3">
                <label className="skills-form--label">Level</label>
                <Select
                  options={ levels.map((item: string) => ({ value: item as SkillLevelT, label: item as SkillLevelT })) }
                  maxMenuHeight={ 200 }
                  menuPlacement={ 'bottom' }
                  isSearchable={ false }
                  onChange={ (selected) => {
                    if (selected?.value) modifySkill(selected.value, skill)
                  } }
                  classNamePrefix={ 'select-field-custom' }
                  defaultValue={ { value: skill.level, label: skill.level } }
                />
              </div>
              <div className="form-group col-3">
                <span className="skills-form--remove" onClick={() => removeSkill(skill)}>&times;</span>
              </div>
            </React.Fragment>
          ))
        }
      </div>
      <div className="form-row">
        <div className="form-group col-6">
          <label className="skills-form--label">{ labels.common }</label>
          <Typeahead
            id={`${id}Skill`}
            ref={(ref) => setTARef(ref)}
            labelKey={labelKey}
            multiple={false}
            onChange={(selected => {
              const value: SkillInterface = (selected && selected[0]) ? selected[0] : '';
              addSkill(value);
            })}
            onInputChange={(text, event) => setValue(text)}
            options={currentOptions}
          />
        </div>
      </div>
    </>
  )
}
